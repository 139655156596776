// TODO: only import what u use
import * as d3 from "d3";
import $ from "jquery";
import { jsonToMatrix } from "../utils/operations";
import { calculateOptimalDomainColor } from "../utils/helpers";

const MARGIN = { TOP: 25, BOTTOM: 25, LEFT: 25, RIGHT: 25 };

class ConfusionMatrix {
	constructor(element, data, updateSelection, shiftKeyPressed, altKeyPressed) {
		this.element = element;
		this.data = data;

		this.updateSelection = updateSelection;
		this.shiftKeyPressed = shiftKeyPressed;
		this.altKeyPressed = altKeyPressed;

		this.createPlot();
	}

	createPlot() {
		if (this.data === undefined) return;

		this.container = d3.select(this.element);

		this.width = $(this.element).height();
		this.height = $(this.element).height();

		this.svg = this.container
			.append("svg")
			.attr("width", this.width)
			.attr("height", this.height)
			.attr("viewBox", [0, 0, this.width, this.height]);

		this.width =
			this.width -
			MARGIN.LEFT / this.data[1].length -
			MARGIN.RIGHT / this.data[1].length;
		this.height =
			this.height -
			MARGIN.TOP / this.data[1].length -
			MARGIN.BOTTOM / this.data[1].length;

		this.xScale = d3.scaleLinear().range([0, this.width - 2]);

		this.yScale = d3.scaleLinear().range([0, this.height - 2]);

		// this.colorScale = d3.scaleSequential(d3.interpolateGreys);
		this.colorScale = d3.scaleSequential(d3.interpolate("#ffffff", "#1976D2"));
		// this.colorScale = d3.scaleSequential(d3.interpolate('#ffffff', '#020B14'));

		this.tooltip = this.container
			.append("div")
			.attr("id", "cm-tooltip")
			.attr("class", "tooltip")
			.style("opacity", 0)
			.style("display", "none");

		this.group = this.svg
			.append("g")
			.attr("transform", `translate(${MARGIN.LEFT * 0}, ${MARGIN.LEFT * 0})`);

		this.background = this.group
			.append("g")
			.attr("class", "cm-background")
			.append("rect")
			.attr("width", this.width)
			.attr("height", this.height)
			.style("fill", "#020B14")
			.style("opacity", 0.1);

		this.updatePlot();
	}

	updatePlot() {
		let model = this.data[0],
			cmData = jsonToMatrix(this.data[1]);

		let plot = this;

		// scale
		plot.xScale.domain([0, cmData.length]);
		plot.yScale.domain([0, cmData.length]);
		plot.colorScale.domain(calculateOptimalDomainColor(cmData));

		cmData = this.data[1]; // NOTE: think of an more elegant way

		// JOIN
		this.rows = plot.group
			.append("g")
			.attr("class", "rows")
			.attr("transform", `translate(${2}, ${2})`)
			.selectAll(".row")
			.data(cmData);

		// ENTER
		this.rows = this.rows
			.enter()
			.append("g")
			.attr("class", "row")
			// TODO: transform => ?
			.attr("transform", (d, i) => `translate(0, ${plot.yScale(i)})`)
			.each((d, i, j) => {
				let row = d3.select(j[i]);
				let rowIdx = i;

				Object.entries(d).forEach(([key, entry], i, j) => {
					row
						.append("rect")
						// needs to be 'class_${key}' bc of numerical strings
						.attr("class", `cell model_${model} class_${key}`)
						.attr("x", plot.xScale(i))
						.attr("width", plot.width / j.length - 2)
						.attr("height", plot.height / j.length - 2)
						.style("fill", plot.colorScale(entry))
						.on("mouseover", (event, x, y, z) => {
							this.tooltip
								.transition()
								.duration(275)
								.style("opacity", 0.8)
								.style("display", "inline-block");

							this.tooltip
								.html(
									"e(c" +
										i +
										"&#8594;c" +
										rowIdx +
										"): " +
										parseFloat(entry).toFixed(5)
								)

								.style("left", event.pageX + event.pageX / 40 + "px")
								.style("top", event.pageY - 20 + "px");
						})
						.on("mouseout", (d) => {
							this.tooltip
								.transition()
								.duration(0)
								.style("opacity", 0)
								.style("display", "none");
						})
						.on("click", (d, i, j) => this.select(j));
				});
			});
	}

	highlightSelection(selection) {
		let allSelected = true;

		for (let key in selection) {
			if (selection[key] === false) allSelected = false;
		}

		// Select Models
		if (!allSelected) {
			Object.entries(selection).forEach(([key, val]) => {
				if (val === true)
					this.group
						.selectAll(`.cell.class_${key}`)
						.style("stroke", "#ff0000")
						.style("stroke-width", "1px");
				else if (val === false)
					this.group
						.selectAll(`.cell.class_${key}`)
						.style("stroke", "#ffffff")
						.style("stroke-width", "0px");
			});
		} else {
			this.group
				.selectAll(".cell")
				.style("stroke", "#ff0000")
				.style("stroke-width", "1px");
		}
	}

	select(selected) {
		let list = selected[0].classList;
		let resultlist = [];

		resultlist[0] = list[1].substring(6, list[1].length);
		//TODO: original        resultlist[1] = list[2].substring(7, list[2].length - 1);
		//TODO anth bugfix:
		//bug war: ${key} lieferte "lass", statt z.B. "class5"
		resultlist[1] = list[2].substring(6, list[2].length);

		let selection = {};
		selection["indicator"] = "COMPLEX";
		selection["modelsWithClasses"] = [];
		selection["modelsWithClasses"][resultlist[0]] = [resultlist[1]];

		if (this.shiftKeyPressed()) {
			selection["mode"] = "OR";
			selection["models"] = [selected];

			this.updateSelection(selection);
		} else if (this.altKeyPressed()) {
			selection["mode"] = "AND";
			selection["models"] = [selected];

			this.updateSelection(selection);
		} else {
			selection["mode"] = "DEFAULT";
			selection["models"] = [selected];

			this.updateSelection(selection);
		}
	}
}

export default ConfusionMatrix;
