import { classicalMDS, transposeMatrix, jsonToMatrix } from "./operations";

/**
 * TODO: Refactor Object.entries to for in !
 */
class DataProcessor {
	constructor(data) {
		this.data = data;
	}

	/**
	 * @return [{ model:'model1', confusionMatrix:[[]] }, {...}, ...]
	 */
	getMatrices() {
		if (this.data == null) return;
		return Object.entries(this.data).map((d) => jsonToMatrix(d[1]));
	}

	getRelativeMatrices() {
		if (this.data == null) return;
		let result = Object.entries(this.data).map((d) => jsonToMatrix(d[1]));
		result = this.absoluteToRelative(result);
		return result;
	}

	/**
	 * @return [{model:'model1', accuracy:0.00}, {...}]
	 */
	getBarChartData() {
		if (this.data == null) return;

		let result = [],
			summedCols = [];

		for (let key in this.data) {
			summedCols.push(this.sumArrayCols(jsonToMatrix(this.data[key])));
		}

		Object.entries(this.data).forEach((d, i) => {
			result.push({
				model: d[0],
				accuracy: this.calculateOverallAccuracy(
					jsonToMatrix(this.data[d[0]]),
					summedCols[i]
				),
			});
		});

		// console.log("test bar chart data");

		return result;
	}

	/**
	 * @return [{model:'model1', x:0.00, y:0.00}, {...}]
	 */
	getConfMatrixSimilarityData() {

		// console.log("test cms data 1")
		
		let data = Object.entries(this.data).map((d) => jsonToMatrix(d[1]));
		// console.log("test cms data 2")
		let result = [];
		let cmsData = classicalMDS(data);
		// console.log("test cms data 3")
		
		// return null;
		
		//for avg accuracies
		
		let summedCols = [];
		for (let key in this.data) {
			summedCols.push(this.sumArrayCols(jsonToMatrix(this.data[key])));
		}
		
		Object.entries(this.data).forEach((d, i) => {
			//            result.push({ 'model': d[0], 'x': cmsData[i][0], 'y': cmsData[i][1] })
			result.push({
				model: d[0],
				x: cmsData[i][0],
				y: cmsData[i][1],
				accuracy: this.calculateOverallAccuracy(
					jsonToMatrix(this.data[d[0]]),
					summedCols[i]
				),
			});
		});
		
		// console.log("test cms data 4")

		// console.log("test conf matrix similarity data");

		return result;
	}

	/**
	 * @return [{model:'model1', class1:0.00, class2:0.00, ...}, {...}, ...]
	 */
	getErrorByClassData() {
		// // console.log('---- getErrorByClassData ----');

		// TODO: optimize performance (multiple Object.key/entry extractions)
		let data = Object.entries(this.data).map((d) => jsonToMatrix(d[1])),
			result = {},
			resultValues = [],
			resultOrder = [
				"model",
				...Object.keys(this.data[Object.keys(this.data)[0]][0]),
			],
			temp = [...this.absoluteToRelative(data)];

		// // console.log('this.data', this.data);
		// // console.log(transposeMatrix(temp[0]))

		// // console.log(this.ignoreCorrectClassification(temp[0]));

		// return // bis hier her alles gut

		Object.entries(this.data).forEach(([key1, val1], i) => {
			let tupel = { model: key1 };
			temp[i] = this.sumArrayCols(this.ignoreCorrectClassification(temp[i]));
			Object.entries(val1[0]).forEach(([key2, val2], j) => {
				tupel[key2] = temp[i][j];
			});
			resultValues.push(tupel);
		});
		result = { values: resultValues, order: resultOrder };

		// // console.log(result);
		// console.log("test error by class data");

		return result;
	}

	/**
	 * @return [{class:'class1', model1:0.00, model2:0.00, ...}, {...}, ...]
	 */
	getErrorByModelData() {
		// TODO:
		// // console.log('---- getErrorByModelData ----');

		let data = Object.entries(this.data).map((d) => jsonToMatrix(d[1])),
			keys = Object.keys(this.data),
			result = {},
			resultValues = [],
			resultOrder = ["class", ...keys],
			temp = this.absoluteToRelative(data);

		for (let i = 0; i < temp.length; i++) {
			temp[i] = this.sumArrayCols(this.ignoreCorrectClassification(temp[i]));
		}

		temp = temp[0].map((col, i) => temp.map((row) => row[i])); // transposes matrix

		Object.entries(this.data[keys[0]][0]).forEach(([key, val], i) => {
			let tupel = { class: key };

			keys.forEach((d, j) => {
				tupel[d] = temp[i][j];
			});
			resultValues.push(tupel);
		});

		result = { values: resultValues, order: resultOrder };

		// console.log("test error by model data");

		return result;
	}

	/**
	 * @return [{model:'model1', class:'class1', class1:0.00, class2:0.00, ...}, {...}, ...]
	 */
	getClassConfusions() {
		// // console.log('---- getClassConfusions ----');

		let data = Object.entries(this.data).map((d) => jsonToMatrix(d[1])),
			result = {},
			resultValues = [],
			resultOrder = [
				"model",
				"class",
				...Object.keys(this.data[Object.keys(this.data)[0]][0]),
			],
			temp = this.absoluteToRelative(data).map((d) =>
				this.ignoreCorrectClassification(d)
			);

		Object.entries(this.data).forEach(([key1, val1], i) => {
			let transposed = transposeMatrix(temp[i]);
			Object.entries(val1[0]).forEach(([key2, val2], j) => {
				let tupel = { model: key1, class: key2 };
				Object.entries(val1[j]).forEach(([key3, val3], k) => {
					tupel[key3] = transposed[j][k];
				});
				resultValues.push(tupel);
			});
		});
		result = { values: resultValues, order: resultOrder };

		// console.log("test class confusions");

		return result;
	}

	/**
	 * @return [[[], ...], ...]
	 */
	getConfusionMatrixData() {
		let data = Object.entries(this.data).map((d) => jsonToMatrix(d[1])),
			result = [],
			test = {},
			temp = this.absoluteToRelative(data);

		Object.entries(this.data).forEach(([key1, val1], i) => {
			let matrix = [];
			Object.entries(val1).forEach(([key2, val2], j) => {
				let tupel = {};
				Object.entries(val2).forEach(([key3, val3], k) => {
					if (j === k) tupel[key3] = 0;
					else tupel[key3] = temp[i][j][k];
				});
				matrix.push(tupel);
			});
			test[key1] = matrix; // TODO: when refactor result[...] = ...
		});
		// TODO: when refactor test = result

		for (let i = 0; i < temp.length; i++) {
			result[i] = this.ignoreCorrectClassification(temp[i]);
		}

		// console.log("test confusion matrix data");

		return test;
	}

	// /**
	// * @return [[[], ...], ...]
	// */
	// getClassnames() {

	// }

	/**
	 * @param {*} data
	 * @return
	 */
	static processAsLabelsInRows(data, modelNames) {
		// console.log(data)

		// TODO:
		// transpose data
		if (data == null) return;

		let result = {};
		let colNames = data.columns;
		let temp = this.sliceInitialData(data);

		for (let i = 0; i < temp.length; i++) {
			let matrixTransposed = transposeMatrix(jsonToMatrix(temp[i]));
			let modelName = modelNames[i];
			let modelResult = matrixTransposed.map((row, i, data) => {
				let resultRow = {};
				for (let rowIndex = 0; rowIndex < row.length; rowIndex++) {
					resultRow[colNames[rowIndex]] = row[rowIndex];
				}
				return resultRow;
			});
			if (modelName === undefined) result["myModel" + (i + 1)] = modelResult;
			else result[modelName] = modelResult;
		}
		// // console.log("prcs as labels in rows ", result);
		return result;
	}

	/**
	 * @param {*} data
	 * @return
	 */
	static processAsLabelsInCols(data, modelNames) {
		// console.log(data)
		if (data == null) return;
		
		// console.log("Test 1");
		let temp = this.sliceInitialData(data);
		let result = {};
		
		// console.log("Test 2");

		// NOTE: Make it sensitive to noise?
		for (let i = 0; i < temp.length; i++) {
			if (modelNames[i] === undefined) result["myModel" + (i + 1)] = temp[i];
			else result[modelNames[i]] = temp[i];
		}
		// console.log("prcs as labels in cols ", result);
		return result;
	}

	/**
	 *
	 * @param {*} matrix
	 * @param {*} summedCols
	 */
	calculateOverallAccuracy(matrix, summedCols) {
		let summedColAccs = 0;
		for (let i = 0; i < matrix.length; i++) {
			summedColAccs += matrix[i][i] / summedCols[i];
		}
		return summedColAccs / matrix[0].length;
	}

	// TODO: rethink design (all-in-one/is something reusable?)
	absoluteToRelative(data) {
		// FALSE ABSOLUTE TO RELATIV
		// console.log('🇸🇭 ', data)

		let transposed_matrices = [];

		for (let i = 0; i < data.length; i++) {
			transposed_matrices.push(transposeMatrix(data[i]));
		}

		// console.log('transposed_matrices ', transposed_matrices);

		let summedCols = [],
			result = [];
		for (let i = 0; i < transposed_matrices.length; i++) {
			summedCols.push(this.sumArrayCols(data[i]));
		}

		// console.log('summedcols', summedCols) // bis hier her passt es

		for (let i = 0; i < transposed_matrices.length; i++) {
			let transposed_matrix = transposed_matrices[i];
			// // console.log('transposed_matrix ', transposed_matrix)
			let relative_model = [];

			for (let j = 0; j < transposed_matrix.length; j++) {
				let row = transposed_matrix[j];
				// // console.log(row);
				let relative_row = [];

				for (let k = 0; k < row.length; k++) {
					let element = row[k];
					if (summedCols[i][j] === 0) {
						// Handle the division by zero case, for example, by setting it to 0 or some other placeholder value
						relative_row.push(0); // Or a placeholder value indicating an undefined or infinite relative value
					} else {
						relative_row.push(element / summedCols[i][j]);
					}
				}
				// // console.log(relative_row)
				relative_model.push(relative_row);
			}
			// // console.log('relative_model', transposeMatrix(relative_model));
			result.push(transposeMatrix(relative_model));
		}

		// console.log('🚻 ', result)

		return [...result];
	}

	/**
	 *
	 * @param {*} confMatrix
	 */
	ignoreCorrectClassification(confMatrix) {
		let result = [...confMatrix];
		for (let i = 0; i < confMatrix.length; i++) {
			for (let j = 0; j < confMatrix[i].length; j++) {
				if (i === j) {
					result[i][j] = 0;
				}
			}
		}
		return result;
	}

	/**
	 *
	 * @param {*} data
	 */
	ignoreCorrectClassificationFullData(data) {
		let result = [];
		for (let i = 0; i < data.length; i++) {
			result.push(this.ignoreCorrectClassification(data[i]));
		}
		return result;
	}

	/**
	 * @param {*} data
	 *
	 * Slicing the data to models based on first row length
	 *
	 * @return [model1:[[0, 1, 2, ...], ...], [...], ...]
	 */
	static sliceInitialData(data) {
		let result = [];
		// // console.log(data);
		let keys = Object.keys(data[0]);
		for (let i = 0; i < data.length - 1; i += keys.length) {
			result.push(data.slice(i, i + keys.length));
		}
		return result;
	}

	/**
	 *
	 * @param {*} array
	 */
	sumArrayCols(array) {
		let temp = [],
			result = [];

		temp = array[0].map((col, i) => array.map((row) => row[i]));
		for (let i = 0; i < temp.length; i++) {
			result.push(temp[i].reduce((acc, curr) => acc + curr, 0));
		}

		return result;
	}
}

export default DataProcessor;
