// TODO: only import what u use
import * as d3 from 'd3';
import $ from 'jquery';

const MARGIN = { TOP: 25, BOTTOM: 25, LEFT: 30, RIGHT: 25 };

class BarChart {
    constructor(element, data, updateSelection, shiftKeyPressed, altKeyPressed, colorScale) {
        this.element = element;
        this.data = data;
        
        this.updateSelection = updateSelection;
        this.shiftKeyPressed = shiftKeyPressed;
        this.altKeyPressed = altKeyPressed;

        this.createPlot();
        this.colorScale = colorScale;
        // // console.log(this.colorScale.domain());

        // console.log("Test 4")
        // console.log("Test 5")
        // console.log("Test 6")
        // console.log("Test 7")
    }

    createPlot() {

        this.container = d3.select(this.element);

        // // console.log(this.element)

        // console.log("Test 1")
        
        // TODO: refactor width and height due to responsiveness 
        this.width = $(this.element).width();
        this.height = $(this.element).height();

        this.svg = this.container.append('svg')
            .attr('viewBox', [0, 0, this.width, this.height]);

        this.width = this.width * .95;
        this.height = this.height * .85;

        this.xScale = d3.scaleBand()
            .range([0, this.width])
        // .padding(0.1); // TODO:

        this.yScale = d3.scaleLinear()
            .range([this.height, 0]);

        this.tooltip = this.container.append('div')
            .attr('id', 'bc-tooltip')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('display', 'none');

        this.groupLines = this.svg.append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.BOTTOM + MARGIN.TOP * 1.25})`);

        this.group = this.svg.append('g')
            // TODO: y translate behaves strangely when done by MARGIN
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.BOTTOM + MARGIN.TOP * 1.25})`);

        this.circleLines = this.svg.append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.BOTTOM + MARGIN.TOP * 1.25})`);


        this.xAxis = this.group.append('g')
            .attr('transform', `translate(0, ${this.height - MARGIN.BOTTOM - MARGIN.TOP})`)
            .attr('class', 'axis xaxis')
            .style('z-index', '200');

        this.yAxis = this.group.append('g')
            .attr('transform', `translate(0, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', 'axis yaxis')
            .style('z-index', '200')
            .style('user-select', 'none');

        // console.log("Test 2")
        
        this.updatePlot();
        
        // console.log("Test 3")
    }

    updatePlot() {
        this.xScale.domain(this.data.map(d => d.model));
        //scale of bar chart value range
        //TODO
        var minVal = d3.min( this.data.map(d => d.accuracy));
        var maxVal = d3.max( this.data.map(d => d.accuracy));
        
        var minValPlot = Math.floor((minVal + Number.EPSILON) * 10) / 10;
        var maxValPlot = Math.min(1.0, Math.ceil((maxVal + Number.EPSILON) * 10) / 10);
        var x_offset = 0.5*this.width / this.data.length;

        this.yScale.domain([minValPlot, maxValPlot]); 

        // TODO: split -> transition
        this.xAxis.call(d3.axisBottom(this.xScale));
        this.yAxis.call(d3.axisLeft(this.yScale));

        this.lines = this.groupLines.selectAll('line')
            .data(this.data);

        this.lines.enter().append('line')
            .attr("transform", `translate(${MARGIN.LEFT * .1}, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', d => `line model_${d.model}`)
            .attr('cv-model', d => d.model)
            .attr('stroke', 'lightgray')
            .attr('stroke-width', 2)
            .style('opacity', 0.7)
            //.style('stroke-dasharray', ('3, 5'))
            .attr('x1', -10)
            .attr('y1', d => this.yScale(d.accuracy))
            .attr('x2', d => this.width)
            .attr('y2', d => this.yScale(d.accuracy))
            .on('mouseover', (event, d) => {
                this.tooltip.transition()
                    .duration(275)
                    .style('opacity', .9)
                    .style('display', 'inline-block')

                this.tooltip.html(`acc: ${d.accuracy.toFixed(4)} (${d.model})`)
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY}px`)
                    .style('z-index', 400);
            })
            .on('mouseout', d => {
                this.tooltip.transition()
                    .duration(0)
                    .style('opacity', 0)
                    .style('display', 'none');
            })
            // .on('click', d => this.select(d.model));


        this.circles = this.group.selectAll('circle')
            .data(this.data);

        this.circles.enter().append('circle')
            .attr("transform", `translate(${MARGIN.LEFT * .1}, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', d => `circle model_${d.model}`)
            .attr('cv-model', d => d.model)
            .attr('cx', d => this.xScale(d.model)+x_offset)
            .attr('cy', d => this.yScale(d.accuracy))
            .attr('r', 10)
            .style('opacity', 0.8)
            .style('stroke', 'black')
            // .style('fill', d => {
                // // console.log(d.model);
                // return this.colorScale(d.model);
            // })
            .style('fill', '#1976D2')
            .on('mouseover', (event, d) => {
                this.tooltip.transition()
                    .duration(275)
                    .style('opacity', .9)
                    .style('display', 'inline-block')

                this.tooltip.html(`acc: ${d.accuracy.toFixed(4)} (${d.model})`)
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY}px`)
                    .style('z-index', 400);
            })
            .on('mouseout', d => {
                this.tooltip.transition()
                    .duration(0)
                    .style('opacity', 0)
                    .style('display', 'none');
            })
            .on('click', (event, d) => this.select(d.model));



            this.circlesLines = this.circleLines.selectAll('line')
                .data(this.data);

            this.circlesLines.enter().append('line')
                .attr('transform', `translate(${MARGIN.LEFT * .1}, -${MARGIN.BOTTOM + MARGIN.TOP})`)
                .attr('class', d => `line model_${d.model}`)
                .attr('cv-model', d => d.model)
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('x1', d => this.xScale(d.model)+x_offset -20)
                .attr('y1', d => this.yScale(d.accuracy))
                .attr('x2', d => this.xScale(d.model)+x_offset +20)
                .attr('y2', d => this.yScale(d.accuracy))
                .on('mouseover', (event, d) => {
                    this.tooltip.transition()
                        .duration(275)
                        .style('opacity', .9)
                        .style('display', 'inline-block')
    
                    this.tooltip.html(`acc: ${d.accuracy.toFixed(4)} (${d.model})`)
                        .style('left', `${event.pageX}px`)
                        .style('top', `${event.pageY}px`)
                        .style('z-index', 400);
                })
                .on('mouseout', d => {
                    this.tooltip.transition()
                        .duration(0)
                        .style('opacity', 0)
                        .style('display', 'none');
                })
                .on('click', d => this.select(d.model));
            
            this.group.selectAll('g.axis text')
                .style('pointer-events', 'none')
                .style('user-select', 'none');
    }

    highlightSelection(selection) {
        this.unhighlightAll();
        Object.entries(selection).forEach(([key, val]) => {
            let keys = Object.keys(val); 
            let thruthyIterator = 0;
            let threshold = keys.length;
            keys.forEach(key => {            
                if(val[key] === true) thruthyIterator++;
            })
            if (thruthyIterator === threshold) {
                this.group.select(`.circle.model_${key}`).style('fill', '#1976D2');
                this.groupLines.select(`.line.model_${key}`).style('stroke', '#1976D2');
            }
        });
    }

    select(selected) {
        let selection = { };

        // // console.log()

        selection['indicator'] = 'MODEL';
        if(this.shiftKeyPressed()) {
            selection['mode'] = 'OR';
            selection['models'] = [selected];

            this.updateSelection(selection);
        } 
        else if(this.altKeyPressed()){
            selection['mode'] = 'AND';
            selection['models'] = [selected];

            this.updateSelection(selection);
        }
        else {
            selection['mode'] = 'DEFAULT';
            selection['models'] = [selected];

            this.updateSelection(selection);
        }
    }

    unhighlightAll() {
        this.group.selectAll('circle.circle').style('fill', 'rgba(2, 11, 20, .15)');
        this.groupLines.selectAll('line.line').style('stroke', 'lightgray');
    }

    resetHighlight() {
        this.group.selectAll('.circle').style('fill', '#1976D2');
        this.groupLines.selectAll('line.line').style('stroke', 'lightgray');
    }
}

export default BarChart;