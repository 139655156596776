import React from 'react';
import Toast from 'react-bootstrap/Toast';

class CVToast extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            timer: 1
        }

        switch (this.props.role) {
            case 'success': this.toastColor = '#28a745'; this.header = "Success!"; break;
            case 'error': this.toastColor = '#dc3545'; this.header = "Error!"; break;
            case 'info': this.toastColor = '#020B14'; this.header = "Info"; break;
            default: this.toastColor = '#ffffff'; this.header = "This should not be here..."; break;
        }
    }

    componentDidMount() {
        this.startTimer();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.timer > 9) this.close();
    }

    startTimer() {
        this.interval = setInterval(() => this.setState({ timer: this.state.timer + 1 }), 1000);
    }

    close = () => this.props.removeToast(this);

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let timerString;

        if(this.state.timer < 3 ) timerString = 'just now.'
        else if(this.state.timer < 6 ) timerString = this.state.timer + ' sec';
        else timerString = 'a while ago.'

        return (
            <Toast key={this.id}>
                <Toast.Header closeButton={false}>
                    <svg width="20px" height="20px" className="rounded mr-2">
                        <rect width="20px" height="20px" style={{ fill: this.toastColor }}></rect>
                    </svg>
                    <strong className="mr-auto">{this.header}</strong>
                    <small> { timerString } </small>
                    <button type="button" className="toast-button close ml-2 mb-1" onClick={this.close}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Toast.Header>
                <Toast.Body>
                    {this.props.message}
                </Toast.Body>
            </Toast>
        )
    }

}

export default CVToast;