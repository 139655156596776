// TODO: only import what u use
import * as d3 from 'd3';
import $ from 'jquery';
import { calculateOptimalDomain } from '../utils/helpers';


const MARGIN = { TOP: 25, BOTTOM: 25, LEFT: 25, RIGHT: 25 };

class ParallelCoordinate {

    constructor(element, data, classNames, updateSelection, shiftKeyPressed, altKeyPressed) {
        this.element = element;
        this.data = data.values;
        this.axes = data.order;
        // this.classNames = classNames

        this.updateSelection = updateSelection;
        this.shiftKeyPressed = shiftKeyPressed;
        this.altKeyPressed = altKeyPressed;

        this.createPlot();
    }

    createPlot() {
        // console.log(this);

        if (this.data === undefined) return;

        // console.log(this.data);

        this.container = d3.select(this.element);

        this.width = $(this.element).width();
        this.height = $(this.element).height();

        this.svg = this.container.append('svg')
            .attr('viewBox', [0, 0, this.width, this.height]);
        // .attr('width', this.width)
        // .attr('height', this.width);

        this.width = this.width - MARGIN.LEFT - MARGIN.RIGHT;
        this.height = this.height - MARGIN.TOP - MARGIN.BOTTOM;

        this.xScale = d3.scaleBand()
            .range([0, this.width]);

        this.yScales = [];

        this.colorScale = d3.scaleSequential().interpolator(d3.interpolateRainbow); // TODO:

        // TODO: decouple data
        // this.axes = d3.keys(this.data[0]);

        this.axes.forEach(axis => {
            if (typeof this.data[0][axis] !== 'number') {
                this.yScales[axis] = d3.scalePoint()
                    .range([this.height, 0]);
            }
            else {
                this.yScales[axis] = d3.scaleLinear()
                    .range([this.height, 0])
                    .nice();
            }

            this.yScales[axis].brush = d3.brushY()
                .extent([[-10, -2], [10, this.height+2]])
                .on('end', (event, d) => this.brushend(event, d))
                .keyModifiers(false)
                ;
        });

        this.tooltip = this.container.append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('display', 'none');

        this.group = this.svg.append('g')
            .attr('class', 'pc-plot-group')
            .attr('transform', `translate(${MARGIN.LEFT * 2}, ${MARGIN.TOP})`);

        this.yAxes = this.group.append('g')
            .attr('class', 'pc-axes-group')
            .attr('transform', `translate(${0}, ${0})`)
            .style('z-index', '200');;

        this.yAxis = this.yAxes.selectAll('foo')
            .data(this.axes).enter();


        this.updatePlot();
    }


    updatePlot() {
        // console.log(this.data);

        let plot = this

        plot.xScale.domain(plot.axes);

        // console.log('plot.axes', plot.axes[0]);

        // console.log('par coord plot color domain', [0, this.data.length])
        plot.colorScale.domain([0, this.data.length])

        // TODO: useless, as long as only error-by-class and error-by-model are diplayed
        // if (plot.axes[0] === 'model' && plot.axes[1] === 'class') plot.colorScale.domain([0, this.data.length/10]);
        // else plot.colorScale.domain([0, this.data.length]);
        

        plot.axes.forEach((axis, i) => {

            if (plot.paths !== undefined) return;
            
            if (typeof plot.data[0][axis] !== 'number') plot.yScales[axis].domain(plot.data.map(i => i[axis]));
            else if (axis === 'model' || axis === 'class') plot.yScales[axis].domain(plot.data);
            else plot.yScales[axis].domain(calculateOptimalDomain(plot.data));
        });

        this.yAxis
            .append('g')
            .attr('class', 'axis')
            .attr('transform', d => `translate(${plot.xScale(d)}, 0)`)
            .attr('shape-rendering', 'crispEdges')
            .style('user-select', 'none')
            .each((d, i, j) => d3.select(j[i]).call(d3.axisLeft(this.yScales[d])))
            .each((d, i, j) => d3.select(j[i]).call(this.yScales[d].brush))
            .append('text')
            .attr("transform", `translate(15, -10)`)
            .attr('fill', '#020B14')
            .attr('fill', '#1976D2')
            .attr('font-weight', 600)
            .attr('font-size', '.75rem')
            .text(d => d);

        this.group.selectAll('g.tick')
            .style('font-weight', 600)
            .style('user-select', 'none')
            .style('pointer-events', 'none')
            ;

        // JOIN
        this.paths = this.group
            .selectAll('path.plane')
            .data(this.data);

        // ENTER
        this.paths.enter()
            .append('path')
            .attr('class', d => {
                // console.log(d)
                // console.log(plot.axes)

                // needs to be 'class${key}' bc of numerical strings for class naming
                if (plot.axes[0] === "model" && plot.axes[1] === 'class') return `plane model_${d[plot.axes[0]]}class_${d[plot.axes[1]]}`;
                else if (plot.axes[0] === "model") return `plane model_${d[plot.axes[0]]}`;
                else return `plane class_${d[plot.axes[0]]}`;
            })
            .attr('d', path)
            .style('fill', 'none')
            .style('stroke', (d, i) => {
                if (plot.axes[0] === "model" && plot.axes[1] === 'class') return this.colorScale(i % 10); // not for other data sets
                return this.colorScale(i);
                // return '#1976D2'
            })
            .style('stroke-width', '1.5px')
            .style('stroke-linecap', 'round')
            .style('shape-rendering', 'geometricPrecision')
            .on('mouseover', (event, d, i) => {
                this.tooltip.transition()
                    .duration(275)
                    .style('opacity', .9)
                    .style('display', 'block')

                let ttText = `pc-tooltip`;

                // console.log(d);

                if (plot.axes[0] === "model" && plot.axes[1] === 'class') ttText = `model: ${d[plot.axes[0]]}, class: ${d[plot.axes[1]]}`;
                else if (plot.axes[0] === "model") ttText = `model: ${d[plot.axes[0]]}`;
                else ttText = `class: ${d[plot.axes[0]]}`;

                this.tooltip.html(ttText)
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY}px`)
                    // .style('z-index', 400)
                    ;
            })
            .on('mouseout', d => {
                this.tooltip.transition()
                    .duration(0)
                    .style('opacity', 0)
                    .style('display', 'none');
            });

        function path(d) {
            
            let line = d3.line()(plot.axes.map(axis => {
                // console.log('1',plot.xScale(axis), plot.yScales[axis](d[axis]))
                // console.log('2',[(plot.xScale(axis)), plot.yScales[axis](d[axis])])
                return [(plot.xScale(axis)), plot.yScales[axis](d[axis])]
            }));
            // console.log(line)
            return line;
        }

        // raises the svg z-oder (z-index) of axis 
        this.yAxes.raise();
    }

    brushend(event, d) {
        // ebc => every trait -> model
        // ebm => every trait -> class
        // cc => every trait -> model&class
        let range = event.selection
        if (range === null) return;

        let selection = [];

        this.data.forEach(instance => {
            let val = this.yScales[d](instance[d]);
            if (val > range[0] && val < range[1]) {
                selection.push(instance);
            };
        })
        this.select(selection);
    }

    highlightSelection(selection) {
        this.unhighlightAll();

        // console.log(selection);

        let selectionModels = Object.keys(selection);
        let classIterators = {};
        let classThreshold = selectionModels.length;

        selectionModels.forEach((modelKey, i) => {
            let selectionClasses = Object.keys(selection[modelKey]);
            let modelIterator = 0;
            let modelThreshold = selectionClasses.length;
            let errorByClassIterator = 0;

            // console.log('selectionModelsIteration');

            // highlighting class confusions
            selectionClasses.forEach((classKey, i) => {
                if (classKey === 'all') return;
                errorByClassIterator = i;   
                // console.log('selectionClassesIteration');
                // console.log(modelKey, classKey)
                // console.log(selection[modelKey][classKey])
                if (selection[modelKey][classKey] === true) {
                    // console.log('#################### TRUE ####################')
                    // console.log(modelKey, classKey)
                    // console.log(selectionClasses)

                    if (classIterators[classKey] === undefined) classIterators[classKey] = 1;
                    else classIterators[classKey]++

                    // console.log(classKey, i)
                    // console.log(this.colorScale(i))

                    // console.log(classKey)
                    // console.log(modelKey)

                    this.group.select(`path.plane.model_${modelKey}class_${classKey}`).style('stroke', this.colorScale(i));
                    modelIterator++;
                }
            });

            // console.log(modelKey);
            // console.log(modelIterator, modelThreshold);
            // highlighting error-by-class
            if (modelIterator === modelThreshold) {
                // console.log(modelKey)
                this.group.select(`path.plane.model_${modelKey}`).style('stroke', this.colorScale(i));
            }

        })

        //highlighting error-by-model
        Object.keys(classIterators).forEach((classIteratorKey, i) => {
            if (classIterators[classIteratorKey] === classThreshold) {
                // console.log(classIteratorKey, this.colorScale(classIteratorKey), i, this.colorScale(i))
                this.group.select(`path.plane.class_${classIteratorKey}`).style('stroke', this.colorScale(classIteratorKey.replace(/\D/g,'')));
            }
        })

    }

    select(selected) {
        // console.log(selected);
        if (selected.length === 0) return;
        let selection = {};
        // let keys = [...Object.keys(selected[0])].sort();
        let keys = [...Object.keys(selected[0])];

        // console.log('selected: ', selected);
        // console.log('key:', keys);

        // let allBrushes = d3.select('foo').selectAll('g.brush');
        // console.log(keys);

        // let lastKey = keys.pop(); // numerical headers
        // let penultimateKey = keys.pop(); // numerical headers

        let firstKey = keys[0];
        let secondKey = keys[1];
        
        
        // console.log(lastKey, penultimateKey)

        // console.log(lastKey); console.log(penultimateKey);

        // TODO: optimize
        if (this.shiftKeyPressed()) {
            selection['mode'] = 'OR';
            // if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
            if (firstKey === 'model' && secondKey === 'class') {
                selection['indicator'] = 'COMPLEX';
                selection['modelsWithClasses'] = [];
                let wrapper = selection['modelsWithClasses'];
                selected.forEach(instance => {
                    let temp = wrapper[instance.model]
                    if (temp === undefined) wrapper[instance.model] = [instance.class];
                    else temp.push(instance.class);
                })
                // console.log(selected);
                // console.log(selection);
                this.updateSelection(selection);
            }
            // else if (lastKey === 'model') { // numerical headers
            else if (firstKey === 'model') {
                selection['indicator'] = 'MODEL';
                selection['models'] = [];
                selected.forEach(instance => {
                    selection['models'].push(instance.model);
                })
                this.updateSelection(selection);
            }
            else {
                selection['indicator'] = 'CLASS';
                selection['classes'] = [];
                selected.forEach(instance => {
                    selection['classes'].push(instance.class);
                })
                this.updateSelection(selection);
            }
        }
        else if (this.altKeyPressed()) {
            selection['mode'] = 'AND';
            // if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
            if (firstKey === 'model' && secondKey === 'class') {
                selection['indicator'] = 'COMPLEX';
                selection['modelsWithClasses'] = [];

                let wrapper = selection['modelsWithClasses'];
                selected.forEach(instance => {
                    let temp = wrapper[instance.model]
                    if (temp === undefined) wrapper[instance.model] = [instance.class];
                    else temp.push(instance.class);
                })
                // console.log(selection)
                this.updateSelection(selection);
            }
            // else if (lastKey === 'model') { // numerical headers
            else if (firstKey === 'model') {
                selection['indicator'] = 'MODEL';
                selection['models'] = [];
                selected.forEach(instance => {
                    selection['models'].push(instance.model);
                })
                this.updateSelection(selection);
            }
            else {
                selection['indicator'] = 'CLASS';
                selection['classes'] = [];
                selected.forEach(instance => {
                    selection['classes'].push(instance.class);
                })
                this.updateSelection(selection);
            }
        }
        else {
            // let brushes = this.group.selectAll('g.brush').clear();
            selection['mode'] = 'DEFAULT';
            // if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
            if (firstKey === 'model' && secondKey === 'class') {
                selection['indicator'] = 'COMPLEX';
                selection['modelsWithClasses'] = [];
                let wrapper = selection['modelsWithClasses'];
                selected.forEach(instance => {
                    let temp = wrapper[instance.model]
                    if (temp === undefined) wrapper[instance.model] = [instance.class];
                    else temp.push(instance.class);
                })
                // console.log(selection)
                this.updateSelection(selection);
            }
            // else if (lastKey === 'model') { // numerical headers
            else if (firstKey === 'model') {
                selection['indicator'] = 'MODEL';
                selection['models'] = [];
                selected.forEach(instance => {
                    selection['models'].push(instance.model);
                })
                this.updateSelection(selection);
            }
            else {
                selection['indicator'] = 'CLASS';
                selection['classes'] = [];
                selected.forEach(instance => {
                    selection['classes'].push(instance.class);
                })
                this.updateSelection(selection);
            }
        }
    }

    unhighlightAll() {
        this.group.selectAll('path.plane').style('stroke', 'rgba(2, 11, 20, .15)');
        // this.group.selectAll('path.plane').style('fill', 'rgba(0, 150, 150, .15)');
    }

    resetSelection() {
        //
    }
}

export default ParallelCoordinate;