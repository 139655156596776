import { max, min } from 'd3';
import { strict_jsonToMatrix } from './operations';

/**
 * @param {*} data
 * @return array
 * 
 * calculates optimal domain to have a smooth visual and interactive experience
 */
export function calculateOptimalDomain(data) {
    // console.log('---- optimalDomain ---');
    let matrix = strict_jsonToMatrix(data);
    let maxVal = max(matrix, (row) => max(row));
    let minVal = min(matrix, (row) => min(row));

    // adapts minVal and maxVal for better experience
    return [(minVal - (minVal * .025)), (maxVal * 1.025)];
}

export function calculateOptimalDomainColor(data) {
    // console.log('---- optimalDomain ---');
    let matrix = strict_jsonToMatrix(data);
    let maxVal = max(matrix, (row) => max(row));
    let minVal = min(matrix, (row) => min(row));

    // adapts minVal and maxVal for better experience
    // console.log([minVal, maxVal])
    return [minVal, maxVal];
}

export function checkAndReturnData(data) {
    // console.log(data);

    let classes = data['columns'];
    let matrix = [];

    let rowThreshold = classes.length;
    let elementThreshold = classes.length;

    let rowIterator = 0;
    let elementIterator = 0;

    Object.keys(data).forEach((rowKey, i) => {
        if (rowKey === 'columns') return;
        // console.log(i, rowIterator);

        let row = {};

        Object.keys(data[rowKey]).forEach((elementKey, i) => {
            if (data[rowKey][elementKey].trim() === '') throw "CORRUPTED DATA ERROR - Whitespace in data"
            row[elementKey.trim()] = parseInt(data[rowKey][elementKey].trim());
            // row[elementKey.trim()] = data[rowKey][elementKey].trim();
            // console.log(elementIterator);

            elementIterator++;
            if (i % elementThreshold === classes.length - 1) elementIterator = 0;
        });
        // check if elementThreshold citeria is satisfied
        if (elementIterator !== 0) throw "DATA ERROR - Incorrect column size";

        rowIterator++;
        if (i % rowThreshold === classes.length - 1) rowIterator = 0;

        matrix[rowKey] = row;
    });

    // check if everythings fine
    if (rowIterator !== 0) throw "DATA ERROR - Models not NxN";
    // console.log(rowIterator); // fine if 0

    matrix['columns'] = [];

    classes.forEach(element => { matrix['columns'].push(element.trim()); });

    // console.log(matrix);
    return matrix;
}

export function initModelNames(data) {
    // console.log(data);

    let modelNames = {};

    let classes = data['columns'];

    let modelIterator = 1;
    Object.keys(data).forEach((rowKey, i) => {
        if (rowKey === 'columns') return;
        if (i % classes.length === classes.length - 1) {
            //TODO: at changed -- for screenshots M1, M2, ...
            //modelNames['model' + modelIterator] = 'model' + modelIterator;
            modelNames['M' + modelIterator] = 'M' + modelIterator;
            modelIterator++;
        }
    });

    // console.log(modelNames);

    return modelNames;
}

// TODO: redundant if app adjusted
export function initClassNames(data) {
    let classNames = {};

    data['columns'].forEach((columnName, i) => { classNames['class' + i] = columnName });

    return classNames;
}


// extracts the model number from the model name -- TODO will fail 
// TODO: will fail for model names other than "M1", "M2", ... -- also for data sets with textual model names
export function extractModelNumber(modelKey){
//    let modelNumber = String(modelKey).parseInt( modelKey.substring(1, 3));
    let modelNumber = parseInt( modelKey.substring(1, 3));
    return modelNumber; 
}