/**
 * metrics.js
 *
 * Class for methods calculating metrics (primarily used in Metrics.js)
 * TODO: put in data processor?
 */

/**
 * Calculates average accuracy over all models
 */
export function calculateAverageAccuracy(data) {
	if (data == null) return;

	let summedaccuracy = 0;
	for (let i = 0; i < data.length; i++) {
		summedaccuracy += data[i]["accuracy"];
	}
	return summedaccuracy / data.length;
}

/**
 *
 */
export function calculateAveragePrevalence(data) {
	if (data == null) return;

	let modelPrevalences = [];
	for (let i = 0; i < data.length; i++) {
		let confMatrix = data[i].map((d) => d);
		let transposedConfMatrix = data[i][0].map((_, colIndex) =>
			data[i].map((row) => row[colIndex])
		);

		let classPrevalences = [];

		for (let j = 0; j < confMatrix.length; j++) {
			let tp = confMatrix[j][j];
			let fp = confMatrix[j].reduce((total, curr) => total + curr) - tp;
			let fn =
				transposedConfMatrix[j].reduce((total, curr) => total + curr) - tp;

			let sumOfAll = confMatrix
				.reduce((a, b) => {
					return a.concat(b);
				})
				.reduce((a, b) => a + b);

			let tn = sumOfAll - tp - fp - fn;
			let classPrevalence = (tp + fn) / (tp + fn + fp + tn);
			classPrevalences.push(classPrevalence);
		}
		let modelPrevalence =
			classPrevalences.reduce((total, curr) => total + curr) /
			confMatrix.length;
		modelPrevalences.push(modelPrevalence);
	}
	return modelPrevalences.reduce((total, curr) => total + curr) / data.length;
}

/**
 *
 */
export function calculateMacroAveragePrecision(data) {
	if (data == null) return;

	let modelRecalls = [];

	for (let i = 0; i < data.length; i++) {
		let confMatrix = data[0];

		let classRecall = confMatrix.map((row, colIndex) => {
			let tp = row[colIndex];
			let tpfn = row.reduce((total, curr) => total + curr);
			return tp / tpfn;
		});

		let modelRecall =
			classRecall.reduce((total, current) => {
				return total + current;
			}) / classRecall.length;

		modelRecalls.push(modelRecall);
	}

	return (
		modelRecalls.reduce((total, curr) => {
			return total + curr;
		}) / data.length
	);
}

/**
 *
 */
export function calculateMacroAverageRecall(data) {
	if (data == null) return;

	let modelPrecisions = [];
	for (let i = 0; i < data.length; i++) {
		let transposedConfMatrix = data[i][0].map((_, colIndex) =>
			data[i].map((row) => row[colIndex])
		);

		let classPrecisions = transposedConfMatrix.map((col, rowIndex) => {
			let tp = col[rowIndex];
			let tpfp = col.reduce((total, curr) => total + curr);
			return tp / tpfp;
		});

		let modelPrecision =
			classPrecisions.reduce((total, current) => {
				return total + current;
			}) / classPrecisions.length;

		modelPrecisions.push(modelPrecision);
	}

	return (
		modelPrecisions.reduce((total, curr) => {
			return total + curr;
		}) / data.length
	);
}

/**
 *
 */
export function calculateMacroAverageF1Score(data) {
	if (data == null) return;

	let modelF1Scores = [];
	for (let i = 0; i < data.length; i++) {
		let confMatrix = data[i].map((d) => d);
		let transposedConfMatrix = data[i][0].map((_, colIndex) =>
			data[i].map((row) => row[colIndex])
		);

		let classF1Scores = [];
		for (let j = 0; j < confMatrix.length; j++) {
			let tp = confMatrix[j][j];
			let fp = confMatrix[j].reduce((total, curr) => total + curr) - tp;
			let fn =
				transposedConfMatrix[j].reduce((total, curr) => total + curr) - tp;
			let classF1Score = tp / (tp + 0.5 * (fp + fn));
			classF1Scores.push(classF1Score);
		}

		let modelF1Score =
			classF1Scores.reduce((total, curr) => total + curr) / confMatrix.length;

		modelF1Scores.push(modelF1Score);
	}

	return modelF1Scores.reduce((total, curr) => total + curr) / data.length;
}
