import * as numeric from 'numericjs';
import DataProcessor from './DataProcessor';

/**
 * @param {*} data
 * @return [[...], ...]
 * 
 */
export function jsonToMatrix(data) {
    // // console.log('---- jsonToMatrix ---');
    // // console.log(data)
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let row = [];
        // // console.log(data[i]);
        for (let key in data[i]) {
            row.push(Number(data[i][key]));
        }
        result.push(row);
    }
    // // console.log(result);
    return result;
}

export function strict_jsonToMatrix(data) {
    // // console.log('---- jsonToMatrix ---');
    // // console.log(data)
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let row = [];
        // // console.log(data[i]);
        for (let key in data[i]) {
            // // console.log(data[i][key], typeof data[i][key])
            // TODO: throw error? Error handling (Handle NaN)
            if(typeof data[i][key] === 'string') continue;
            else row.push(Number(data[i][key]));
        }
        result.push(row);
    }
    // // console.log(result);
    return result;
}

/**
 * @param {*} data
 * @return
 * 
 * Transposes matrix A(n x n) 
 */
export function transposeMatrix(matrix) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

/**
* @param {*} data
* 
* Performs classical multi-dimensional scaling 
*/
export function classicalMDS(data) {

    // console.log("Test in classicalMDS 1")
    
    let dimensions = 2;
    //converts the absolute numbers in the confusion matrices to percentages (per-class)
    let data_percentages = new DataProcessor().absoluteToRelative(data);
    // console.log("Test in classicalMDS 2")
    
    //let distances = distanceMatrix(data_percentages, frobeniusNorm);
    let distances = distanceMatrix(data_percentages, mannhattanDistance_scaled);
    // console.log("Test in classicalMDS 4")
    
    //classical MDS from: http://www.benfrederickson.com/multidimensional-scaling/
    let M = numeric.mul(-.5, numeric.pow(distances, 2));
    // console.log("Test in classicalMDS 5")
    
    function mean(A) { return numeric.div(numeric.div(numeric.add.apply(null, A), A.length)); }
    // console.log("Test in classicalMDS 6")
    
    let rowMeans = mean(M),
    colMeans = mean(numeric.transpose(M)),
    totalMean = mean(rowMeans);
    
    // console.log("Test in classicalMDS 7")
    // console.log("data_percentages: ", data_percentages)
    // console.log("distances: ", distances)
    // console.log("M: ", M)
    // console.log("rowMeans: ", rowMeans)
    // console.log("colMeans: ", colMeans)
    // console.log("totalMean: ", totalMean)
    
    for (let i = 0; i < M.length; ++i) {
        for (let j = 0; j < M[0].length; ++j) {
            M[i][j] += totalMean - rowMeans[i] - colMeans[j];
        }
    }
    // console.log("Test in classicalMDS 8")
    // console.log("M: ", M)

    // filter NaN values  
    let cleanedMatrix = M.map(row => row.filter(value => !isNaN(value)));
    M = cleanedMatrix;

    // // console.log("Test in classicalMDS 9")
    // // console.log(M)
    
    // return null;
    
    // take the SVD of the double centred matrix, and return the points from it
    //TODO: catch Exception if no convergence
    // console.log("Test in classicalMDS before 10")
    // console.log("M: ", M)

    let ret = numeric.svd(M);
    // console.log("Test in classicalMDS 10")
    let eigenValues = numeric.sqrt(ret.S);
    // console.log("Test in classicalMDS 11")
    
    // return null;


    let result = ret.U.map(function (row) {
        return numeric.mul(row, eigenValues).splice(0, dimensions)
    });


    // ---- for better interpretation of the plot: shift MDS projection, such that we have values >=0
    let x_min = Number.MAX_SAFE_INTEGER;
    let y_min = Number.MAX_SAFE_INTEGER;
    let x_max = Number.MIN_SAFE_INTEGER;
    let y_max = Number.MIN_SAFE_INTEGER;

    
    //to shift the 2D MDS projection: calc min values per dimension 
    for(let idx=0; idx<result.length; idx++){
        let x_val = result[idx][0];
        let y_val = result[idx][1];
        x_min = Math.min(x_val, x_min)
        y_min = Math.min(y_val, y_min)
        x_max = Math.max(x_val, x_min)
        y_max = Math.max(y_val, y_min)
    }

    //... shift per dimension
    for(let idx=0; idx<result.length; idx++){
        let x_val = result[idx][0] -= x_min;
        let y_val = result[idx][1] -= y_min;
    }


    //determine orientation -- to allow best space for model names like M1, M2, .. (labels for names have width > height)
    // if( (x_max-x_min) > (y_max-y_min) ){
    //     //we want the wider range to be shown vertically, switch x and y: 
    //     let result_switchedXY = [];
    //     for(let idx=0; idx<result.length; idx++){
    //         result_switchedXY.push([result[idx][1], result[idx][0]]);
    //     }
    //     result = result_switchedXY;
    // }

    return result;
}

/**
 * 
 * @param {*} data 
 */
function distanceMatrix(data, distanceMeasure) {
    // initializes data.length by data.length big array of zeros
    let result = [...Array(data.length)].map((d, i) => Array(data[i].length).fill(0));

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data.length; j++) {
            result[i][j] = distanceMeasure(data[i], data[j]);
        }
    }
    return result;
}

/**
 * 
 * @param {*} matrix1 
 * @param {*} matrix2 
 */
function frobeniusNorm(matrix1, matrix2) {

    let sum = 0;
    for (let i = 0; i < matrix1.length; i++) {
        for (let j = 0; j < matrix1[i].length; j++) {
            let sqDiff = Math.pow(matrix1[i][j] - matrix2[i][j], 2);
            sum += sqDiff;
        }
    }
    return Math.sqrt(sum);
}


/**
 * Manhattan distance, scaled to 0...1
 * scaling by dividing by the number of classes
 * and divided by 2, in oder to consider that a complete difference between two classes 
 * yields a Manhattan distance of 2 (e.g. dist_Manhattan( (1,0), (0,1) ) = 2!)
 */
function mannhattanDistance_scaled(matrix1, matrix2) {

    let sum = 0;
    let n_classes = matrix1.length;

    for (let i = 0; i < matrix1.length; i++) {
        for (let j = 0; j < matrix1[i].length; j++) {
            let diff = Math.abs(matrix1[i][j] - matrix2[i][j]);
            let diff_scaled = 0.5 * diff / n_classes;
            sum += diff_scaled;
        }
    }
    return sum;
}

