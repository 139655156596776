import React from "react";
import { BrowserRouter as Router, Link, Routes, Route, Navigate } from "react-router-dom";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChartBar, faDatabase, faInfo, faDivide, } from "@fortawesome/free-solid-svg-icons";

import { csv } from "d3";
import { v4 as uuid } from "uuid";

import CVToast from "./components/CVToast";
import Dashboard from "./container/Dashboard/Dashboard";
import Metrics from "./container/Metrics/Metrics";
import Data from "./container/Data/Data";
import Information from "./container/Information/Information";
import { checkAndReturnData, initModelNames, /*initClassNames*/ } from "./utils/helpers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import MNIST from "./assets/CNN_simple_mnist_kernel_size_strides_20epochs_labels_in_columns­_user_study.csv";


console.warn = () => {};

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			timestamp: null,
			structure: "labelsInCols",
			data: null,
			modelNames: {},
			colorScale: null,
			toaster: [],
			dashboardRenderIndicator: 0,
		};

		// refs
		this.iconLabelWrapperRef = React.createRef();
	}

	componentDidMount() {
		this.initApp();
	}

	// TODO: refactor;
	initApp() {
		csv(MNIST)
			.then((data) => {
				// console.log(data);

				// check models and classes (m -> class.length x class.length)
				let checkedData = checkAndReturnData(data);

				let modelNames = initModelNames(checkedData);
				// let classNames = initClassNames(checkedData); // currently not used

				this.setState({
					data: checkedData,
					modelNames: modelNames,
				});
			})
			.catch((error) => {
				console.error("dip shit")
				console.error(error);
				console.error("Initial data could not be read.");
				this.addToast("error", error);
				this.addToast(
					"error",
					"App initialization went wrong, please upload your data."
				);
			});
	}

	updateStructure = (structure) => {
		console.log(structure);

		this.setState({
			dashboardRenderIndicator: Math.random(),
			structure: structure,
		});
	};

	getStructure = () => {
		return this.state.structure;
	};

	updateData = (data) => {
		this.setState({
			dashboardRenderIndicator: Math.random(),
			data: data,
		});
	};

	getData = () => {
		return this.state.data;
	};

	updateModelNames = (names) => {
		this.setState({
			dashboardRenderIndicator: Math.random(),
			modelNames: names,
		});
	};

	getModelNames = () => {
		return this.state.modelNames;
	};

	addToast = (role, message) =>
		this.setState({
			toaster: [
				...this.state.toaster,
				<CVToast
					id={uuid()}
					role={role}
					message={message}
					removeToast={this.removeToast}
				/>,
			],
		});

	removeToast = (toast) =>
		this.setState({
			toaster: this.state.toaster.filter((item) => {
				return item.props.id !== toast.props.id;
			}),
		});

	collapseNav(event) {
		$(".App-nav").toggleClass("collapsed");
	}

	collapseSidebar(event) {
		// console.log('clickedi click')
		// $('.App-sidebar').toggleClass('collapsed');
		// Deactivated for user study
	}

	/**
	 * TODO: Refactor: NavBar, Sidebar, AppHeader?, RouterLinks?
	 */
	render() {
		return (
			<div className="App flex-grow-1 d-flex flex-column ">
				<div className="App-header d-flex flex-row">
					{/* TODO: implement collapse */}
					<div className="nav-collapse-icon" onClick={this.collapseNav}>
						<FontAwesomeIcon icon={faBars} />
					</div>
					<div className="heading flex-grow-1">
						<span>
							ConfusionVis (for help see 'information' icon in menu bar)
						</span>
					</div>
				</div>
				<Router>
					<div className="App-body flex-grow-1 d-flex flex-row">
						<nav className="App-nav collapsed">
							<ul className="nav d-flex flex-column">
								{/* TODO: refactor to dynamic build */}
								<li className="nav-item">
									<Link className="d-flex flex-row" to="dashboard">
										<div className="icon-wrapper d-flex align-items-center justify-content-center">
											<FontAwesomeIcon icon={faChartBar} />
										</div>
										<div className="icon-label-wrapper">Dashboard</div>
									</Link>
								</li>
								<li className="nav-item">
									<Link className="d-flex flex-row" to="metrics">
										<div className="icon-wrapper d-flex align-items-center justify-content-center">
											<FontAwesomeIcon icon={faDivide} />
										</div>
										<div className="icon-label-wrapper">Metrics</div>
									</Link>
								</li>
								<li className="nav-item">
									<Link className="d-flex flex-row" to="data">
										<div className="icon-wrapper align-items-center justify-content-center">
											<FontAwesomeIcon icon={faDatabase} />
										</div>
										<div className="icon-label-wrapper">Data</div>
									</Link>
								</li>
								<li className="nav-item">
									<Link className="d-flex flex-row" to="information">
										<div className="icon-wrapper align-items-center justify-content-center">
											<FontAwesomeIcon icon={faInfo} />
										</div>
										<div className="icon-label-wrapper">Information</div>
									</Link>
								</li>
								{/* <li className="nav-item">
                  <Link className="d-flex flex-row disabled-link" to="/test">
                    <div className="icon-wrapper align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faTumblr} />
                    </div>
                    <div className="icon-label-wrapper">
                      Test
                    </div>
                  </Link>
                </li> */}
							</ul>
						</nav>
						<main className="App-main flex-grow-1 d-flex flex-column">
							<Routes>
								<Route path="dashboard" element={
									<Dashboard
										key={this.state.dashboardRenderIndicator}
										structure={this.state.structure}
										data={this.state.data}
										modelNames={this.state.modelNames}
										renderSidebar={this.renderSidebar}
										addToast={this.addToast}
										removeToast={this.removeToast}
									/>}
								>	
								</Route>
								<Route path="metrics" element={
									<Metrics
										structure={this.state.structure}
										data={this.state.data}
										modelNames={this.state.modelNames}
									/>
								}>
								</Route>
								<Route path="data" element={
									<Data
										structure={this.state.structure}
										modelNames={this.state.modelNames}
										updateStructure={this.updateStructure}
										updateData={this.updateData}
										updateModelNames={this.updateModelNames}
										addToast={this.addToast}
										removeToast={this.removeToast}
									/>}
								/>
								<Route path="information" element={<Information/>} />
								<Route path="*" element={<Navigate to="dashboard" replace/>} />
							</Routes>
							<div className="toast-container d-flex flex-column">
								{this.state.toaster.map((toast, i) => {
									return (
										<div key={i} className="cv-toast d-flex mb-1">
											{toast}
										</div>
									);
								})}
							</div>
						</main>
					</div>
				</Router>
			</div>
		);
	}
}

export default App;
