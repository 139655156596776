import ConfusionMatrix from "./ConfusionMatrix";
import { select } from 'd3-selection';

class ConfusionMatrixContainer {
    constructor(element, data, updateSelection, shiftKeyPressed, altKeyPressed) {
        this.element = element;
        this.plots = { };

        this.updateSelection = updateSelection;
        this.shiftKeyPressed = shiftKeyPressed;
        this.altKeyPressed = altKeyPressed;
        
        this.createPlot(data);
    }

    createPlot(data) {

        // let container = $(this.element);
        let container = select(this.element)
            .style('width', '100%')
            .style('height', '100%');


        Object.entries(data).forEach(([key, entry], i, j) => {
            let cmHeight, cmWidth = '20%';

            // console.log(key)

            if (j.length <= 10) cmHeight = '49%';
            else if (j.length <= 15) cmHeight = '32.3333%';
            else if (j.length <= 20) cmHeight = '24%';

            let cmCon = container.append('div')
                .attr('class', 'cm d-flex flex-column')
                .style('width', cmWidth)
                .style('height', cmHeight);

            cmCon.append('div')
                .attr('class', 'cm-header d-flex justify-content-center')
                .style('color', '#1976D2')
                .style('font-weight', 600)
                .style('font-size', '.75rem')
                .append('span')
                .html(key);

            let cmBody = cmCon.append('div')
                .attr('class', 'cm-body flex-grow-1 d-flex justify-content-center');
            

                //TODO: AND selection
            this.plots[key] = new ConfusionMatrix(cmBody.node(), [key, entry], this.updateSelection, this.shiftKeyPressed, this.altKeyPressed);
        });
    }

    highlightSelection(selection) {
        Object.keys(this.plots).forEach(key => {
            this.plots[key].highlightSelection(selection[key]);
        })
    }

    resetSelection() {
        //
    }

}

export default ConfusionMatrixContainer;