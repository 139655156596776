// TODO: only import what u use
import * as d3 from "d3";
import { calculateOptimalDomain } from "../utils/helpers";

const MARGIN = { TOP: 25, BOTTOM: 25, LEFT: 25, RIGHT: 0 };

class ClassConfusions {
	constructor(element, data, updateSelection, shiftKeyPressed, altKeyPressed) {
		this.element = element;
		this.data = data.values;
		this.axes = data.order;

		this.updateSelection = updateSelection;
		this.shiftKeyPressed = shiftKeyPressed;
		this.altKeyPressed = altKeyPressed;

		this.create();
	}

	create() {
		//// console.log(this.element)

		if (this.data === undefined || this.data === null) return;
		if (this.element === null) return;

		// // console.log(this.data);
		// // console.log(this.axes);
		// // console.log(this.element);

		this.container = d3.select(this.element);

		this.width = this.element.offsetWidth;
		this.height = this.element.offsetHeight;

		this.classes = this.axes.filter((axis) => {
			if (axis !== "model" && axis !== "class") return axis;
		});
		this.modelValues = this.data.map((i) => i.model);
		this.models = this.modelValues.filter(
			(val, ind) => this.modelValues.indexOf(val) === ind
		);
		this.brushedAxes = {};

		// // console.log(this.width, this.height);
		// // console.log(this.classes);

		this.svg = this.container
			.append("svg")
			.attr("viewBox", [0, 0, this.width, this.height])
			.attr("width", this.width)
			.attr("height", this.height);

		this.width = this.width - MARGIN.LEFT - MARGIN.RIGHT;
		this.height = this.height - MARGIN.TOP - MARGIN.BOTTOM;

		this.xScale = { linear: null, band: null };

		this.xScale.linear = d3.scaleLinear().range([0, this.width]);

		// this.xScale.band = d3.scaleBand() // TODO: check why its not working
		//     .range([0, this.width]);

		this.yScales = [];

		this.colorScale = d3.scaleSequential().interpolator(d3.interpolateRainbow);

		this.axes.forEach((axis, i) => {
			if (axis === "model" || axis === "class") {
				this.yScales[axis] = d3.scalePoint().range([this.height, 0]);

				this.yScales[axis].brush = d3
					.brushY()
					.extent([
						[-10, -2],
						[10, this.height + 2],
					])
					.on("end", (event, d) => this.brushend(event, d))
					.keyModifiers(false);

				this.brushedAxes[axis] = axis;
			} else {
				this.classes.forEach((d, i) => {
					this.yScales[`${d}-${axis}`] = d3
						.scaleLinear()
						.range([this.height, 0])
						.nice();

					if (i === 0) {
						this.yScales[`${d}-${axis}`].brush = d3
							.brushY()
							.on("end", (event, d) => this.brushendBars(event, d))
							.keyModifiers(false);

						this.brushedAxes[`${d}-${axis}`] = this.classes;
					}
				});
			}

			// this.yScales[axis].brush = d3.brushY()
			//     .extent([[-10, -2], [10, this.height+2]])
			//     .on('end', d => this.brushend(d))
			//     .keyModifiers(false)
			// ;<
		});

		// // console.log("brushedAxes", this.brushedAxes);

		this.tooltip = this.container
			.append("div")
			.attr("class", "tooltip")
			.style("opacity", 0)
			.style("display", "none");

		this.group = this.svg
			.append("g")
			.attr("class", "np-this-group")
			.attr("transform", `translate(${MARGIN.LEFT * 2}, ${MARGIN.TOP})`);

		this.yAxes = this.group
			.append("g")
			.attr("class", "np-axes-group")
			.attr("transform", `translate(${0}, ${0})`)
			.style("z-index", "200");

		this.update();
	}

	update() {
		let fullAxes = Object.keys(this.yScales);

		let axesOnlyClasses = fullAxes.filter((val) => {
			if (val !== "model" && val !== "class") return val;
		});
		let models = this.modelValues.filter(
			(val, ind) => this.modelValues.indexOf(val) === ind
		);

		// let bigGap = 0.6 / (models.length + 1);
		let bigGap = 0.5 / (models.length + 1); // TODO: depends on smallGap dividend (dividend(bigGap) + dividend(smallGap) = 1)
		// let smallGap = 0.4 / (this.classes.length * models.length - models.length);
		let smallGap = 0.55 / (this.classes.length * models.length - models.length); // TODO: optimized view for user-study (10Models 10Classes)
		let accumulator = 0;

		this.xScale.linear.domain([0, 1.15]);
		// this.xScale.band.domain([0, 1.15]);

		// // console.log('new plot color domain', [0, models.length])
		this.colorScale.domain([0, models.length]);

		fullAxes.forEach((axis) => {
			if (axis === "model" || axis === "class")
				this.yScales[axis].domain(this.data.map((i) => i[axis]));
			else this.yScales[axis].domain(calculateOptimalDomain(this.data));
		});

		// Get xScaled Values
		this.xScaledValues = {};

		fullAxes.forEach((val, iter) => {
			if (val === "model" || val === "class") {
				this.xScaledValues[val] = this.xScale.linear(accumulator);
				accumulator += bigGap;
			} else {
				if ((iter - 2) % this.classes.length === this.classes.length - 1) {
					this.xScaledValues[val] = this.xScale.linear(accumulator);
					accumulator += bigGap;
				} else {
					this.xScaledValues[val] = this.xScale.linear(accumulator);
					accumulator += smallGap;
				}
			}
		});

		// // console.log("smallgap: ", smallGap, " bigGap: ", bigGap)
		// // console.log(this.xScaledValues)

		let brushedAxesKeys = Object.keys(this.brushedAxes);

		brushedAxesKeys.forEach((axis) => {
			if (axis === "model" || axis === "class") return;
			this.yScales[axis].brush.extent([
				[-2, -2],
				[
					this.xScale.linear(smallGap) * this.classes.length + 2,
					this.height + 2,
				],
			]);
		});

		// Generate data for Squares Model to class to class
		this.squareValues = {};

		this.data.forEach((val, iter) => {
			this.classes.forEach((val2) => {
				this.squareValues[`${val.model}-${val2}-${val.class}`] = val[val2];
			});
		});

		this.yAxes = this.yAxes
			.selectAll("foo")
			.data(Object.keys(this.yScales))
			.enter();

		// Axis
		this.yAxis = this.yAxes
			.append("g")
			.attr("class", "axis")
			.attr("transform", (d) => {
				if (d === "class")
					return `translate(${this.xScaledValues[d] + 17.5}, 0)`; // TODO: de-hardcode "+17.5"
				return `translate(${this.xScaledValues[d]}, 0)`;
			})
			.attr("shape-rendering", "crispEdges")
			.style("user-select", "none")
			.each((axis, i, j) => {
				if (brushedAxesKeys.includes(axis)) {
					if (axis === "model" || axis === "class")
						d3.select(j[i]).call(d3.axisLeft(this.yScales[axis]));
					d3.select(j[i]).call(this.yScales[axis].brush);
				}
			})
			.append("text") // TODO: optimize
			.attr("transform", (d) => `translate(${0}, ${-10})`)
			.attr("fill", "#020B14")
			.attr("fill", "#1976D2")
			.attr("font-weight", 600)
			.attr("font-size", ".75rem")
			.text((d, i) => {
				if ((i - 2) % this.classes.length === 1) {
					// // console.log(d, this.xScaledValues[d])
					let split = d.split("-");
					return split[1];
				}
			});

		this.group
			.selectAll("g.tick")
			.style("font-weight", 600)
			.style("user-select", "none")
			.style("pointer-events", "none");

		// Bars
		this.bars = this.group
			.append("g")
			.attr("class", "np-bars-group")
			.selectAll("rect")
			.data(axesOnlyClasses)
			.enter();

		this.bars
			.append("rect")
			.attr("class", (d) => `bar_${d}`)
			.attr("x", (d) => this.xScaledValues[d])
			.attr("y", 0)
			.attr("height", (d) => this.yScales[d](this.yScales[d].domain()[0]))
			.attr("width", (d) => this.xScale.linear(smallGap))
			.attr("shape-rendering", "crispEdges")
			.style("fill", "none")
			.style("stroke", "lightgray")
			.style("stroke-width", ".5px");

		// Squares / Dots
		this.squares = this.group
			.append("g")
			.attr("class", "np-squares-group")
			.selectAll("rect")
			.data(Object.keys(this.squareValues))
			.enter();

		let SQUAREMARGIN = {
			VERTICAL: 5,
			HORIZONTAL: (this.xScale.linear(smallGap) - 7.4) / 2,
		};

		this.squares
			.append("rect")
			.attr("class", (d) => {
				let split = d.split("-");
				return `square model_${split[0]} class_${split[2]}`;
			})
			.attr("x", (d) => {
				let split = d.split("-");
				return (
					this.xScaledValues[`${split[2]}-${split[1]}`] +
					SQUAREMARGIN.HORIZONTAL
				);
			})
			.attr("y", (d) => {
				let split = d.split("-");
				// // console.log(split, d)
				return (
					this.yScales[`${split[2]}-${split[1]}`](this.squareValues[d]) - 3.1
				);
			})
			.attr("height", 8)
			.attr("width", 8)
			.attr("shape-rendering", "crispEdges")
			.style("fill", (d) => {
				let colorIndicator = parseInt(
					String(d.split("-")[0]).substring(1, 3) - 1
				);

				return this.colorScale(colorIndicator);
				return this.colorScale(String(d.split("-")[0]).substring(5, 7));
			})
			.style("stroke", "lightgray")
			.on("mouseover", (event, d, i) => {
				this.tooltip
					.transition()
					.duration(275)
					.style("opacity", 0.9)
					.style("display", "block");

				let ttText = `pc-tooltip`;
				let split = d.split("-");

				ttText = `e(c${split[2].slice(-1)}&#8594;c${split[1].slice(
					-1
				)}): ${this.squareValues[d].toFixed(5)}`;

				this.tooltip
					.html(ttText)
					.style("left", `${event.pageX}px`)
					.style("top", `${event.pageY}px`);
			})
			.on("mouseout", (d) => {
				this.tooltip
					.transition()
					.duration(0)
					.style("opacity", 0)
					.style("display", "none");
			})
			.on("click", (d) => this.clickSelect(d));

		// Paths
		this.paths = this.group
			.append("g")
			.attr("class", "np-paths-group")
			.selectAll("path.plane")
			.data(this.data);

		this.paths
			.enter()
			.append("path")
			.attr(
				"class",
				(d) => `path model_${d[this.axes[0]]}class_${d[this.axes[1]]}`
			)
			.attr("d", (d) => path(d, this.xScaledValues, this.yScales, this.xScale))
			.style("fill", "none")
			.style("stroke", (d, i) => {
				let colorIndicator = String(d.model).substring(1, 3);
				colorIndicator = parseInt(colorIndicator) - 1;

				return this.colorScale(colorIndicator);
			})
			.style("stroke-width", "1.5px")
			.style("stroke-linecap", "round")
			.style("shape-rendering", "geometricPrecision")
			.on("mouseover", (event, d, i) => {
				this.tooltip
					.transition()
					.duration(275)
					.style("opacity", 0.9)
					.style("display", "block");

				let ttText = `pc-tooltip`;
				ttText = `model: ${d.model}, class: ${d.class}`;

				this.tooltip
					.html(ttText)
					.style("left", `${event.pageX}px`)
					.style("top", `${event.pageY}px`);
			})
			.on("mouseout", (d) => {
				this.tooltip
					.transition()
					.duration(0)
					.style("opacity", 0)
					.style("display", "none");
			});

		// utility
		function path(data, xValues, yScales, xScale) {
			let currentClass = data.class;
			let lineVals = [];

			Object.keys(data).forEach((axis) => {
				if (axis === "model")
					lineVals.push([xValues[axis], yScales[axis](data[axis])]);
				else if (axis === "class")
					lineVals.push([xValues[axis] + 17.5, yScales[axis](data[axis])]);
				else
					lineVals.push([
						xValues[`${currentClass}-${axis}`] + xScale.linear(smallGap) / 2,
						yScales[`${currentClass}-${axis}`](data[axis]),
					]);
			});

			return d3.line()(lineVals);
		}

		this.squares.raise();
		this.yAxes.raise();

		d3.selectAll("g.tick").raise();
	}

	clickSelect(selected) {
		let split = selected.split("-");

		let selectedObject = {};
		if (split.length === 2) {
			// // console.log(split);
			selectedObject["class"] = `${split[1]}`;
			for (let i = 0; i < this.models.length; i++) {
				selectedObject[`${this.models[i]}`] = 0;
			}
		}
		if (split.length === 3) {
			selectedObject["model"] = `${split[0]}`;
			selectedObject["class"] = `${split[2]}`;
		}

		let selection = [selectedObject];

		this.select(selection);
	}

	brushend(event, d) {
		// ebc => every trait -> model
		// ebm => every trait -> class
		// cc => every trait -> model&class

		let range = event.selection;
		if (range === null) return;

		let selection = [];

		this.data.forEach((instance) => {
			let val = this.yScales[d](instance[d]);
			if (val > range[0] && val < range[1]) {
				selection.push(instance);
			}
		});
		this.select(selection);
	}

	brushendBars(event, d) {
		let range = event.selection;
		if (range === null) return;

		let selection = [];
		let confusedTo = d.split("-")[1];

		this.data.forEach((instance) => {
			this.classes.forEach((cla) => {
				let val = this.yScales[`${cla}-${confusedTo}`](instance[confusedTo]);
				if (instance["class"] === cla && val > range[0] && val < range[1]) {
					selection.push(instance);
				}
			});
		});

		this.select(selection);
	}

	select(selected) {
		if (selected.length === 0) return;
		let selection = {};
		let keys = [...Object.keys(selected[0])];

		let firstKey = keys[0];
		let secondKey = keys[1];

		if (this.shiftKeyPressed()) {
			selection["mode"] = "OR";
			// if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
			if (firstKey === "model" && secondKey === "class") {
				selection["indicator"] = "COMPLEX";
				selection["modelsWithClasses"] = [];
				let wrapper = selection["modelsWithClasses"];
				selected.forEach((instance) => {
					let temp = wrapper[instance.model];
					if (temp === undefined) wrapper[instance.model] = [instance.class];
					else temp.push(instance.class);
				});
				this.updateSelection(selection);
			}
			// else if (lastKey === 'model') { // numerical headers
			else if (firstKey === "model") {
				selection["indicator"] = "MODEL";
				selection["models"] = [];
				selected.forEach((instance) => {
					selection["models"].push(instance.model);
				});
				this.updateSelection(selection);
			} else {
				selection["indicator"] = "CLASS";
				selection["classes"] = [];
				selected.forEach((instance) => {
					selection["classes"].push(instance.class);
				});
				this.updateSelection(selection);
			}
		} else if (this.altKeyPressed()) {
			selection["mode"] = "AND";
			// if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
			if (firstKey === "model" && secondKey === "class") {
				selection["indicator"] = "COMPLEX";
				selection["modelsWithClasses"] = [];

				let wrapper = selection["modelsWithClasses"];
				selected.forEach((instance) => {
					let temp = wrapper[instance.model];
					if (temp === undefined) wrapper[instance.model] = [instance.class];
					else temp.push(instance.class);
				});
				this.updateSelection(selection);
			}
			// else if (lastKey === 'model') { // numerical headers
			else if (firstKey === "model") {
				selection["indicator"] = "MODEL";
				selection["models"] = [];
				selected.forEach((instance) => {
					selection["models"].push(instance.model);
				});
				this.updateSelection(selection);
			} else {
				selection["indicator"] = "CLASS";
				selection["classes"] = [];
				selected.forEach((instance) => {
					selection["classes"].push(instance.class);
				});
				this.updateSelection(selection);
			}
		} else {
			selection["mode"] = "DEFAULT";
			// if (lastKey === 'model' && penultimateKey === 'class') { // numerical headers
			if (firstKey === "model" && secondKey === "class") {
				selection["indicator"] = "COMPLEX";
				selection["modelsWithClasses"] = [];
				let wrapper = selection["modelsWithClasses"];
				selected.forEach((instance) => {
					let temp = wrapper[instance.model];
					if (temp === undefined) wrapper[instance.model] = [instance.class];
					else temp.push(instance.class);
				});
				this.updateSelection(selection);
			}
			// else if (lastKey === 'model') { // numerical headers
			else if (firstKey === "model") {
				selection["indicator"] = "MODEL";
				selection["models"] = [];
				selected.forEach((instance) => {
					selection["models"].push(instance.model);
				});
				this.updateSelection(selection);
			} else {
				selection["indicator"] = "CLASS";
				selection["classes"] = [];
				selected.forEach((instance) => {
					selection["classes"].push(instance.class);
				});
				this.updateSelection(selection);
			}
		}
	}

	highlightSelection(selection) {
		this.unhighlightAll();

		let selectionModels = Object.keys(selection);
		let classIterators = {};

		selectionModels.forEach((modelKey, mk) => {
			let selectionClasses = Object.keys(selection[modelKey]);
			let modelIterator = 0;
			let errorByClassIterator = 0;

			// highlighting class confusions
			selectionClasses.forEach((classKey, i) => {
				if (classKey === "all") return;
				errorByClassIterator = i;

				if (selection[modelKey][classKey] === true) {
					if (classIterators[classKey] === undefined)
						classIterators[classKey] = 1;
					else classIterators[classKey]++;

					//TODO: at changed -- for screenshot : M1, ...
					//          this.group.select(`path.path.model_${modelKey}class_${classKey}`).style("stroke", this.colorScale(parseInt(modelKey.substring(5, 7))-1));
					this.group
						.select(`path.path.model_${modelKey}class_${classKey}`)
						.style("stroke", this.colorScale(mk));

					//TODO: at changed -- for screenshot : M1, ...
					//          this.group.selectAll(`rect.square.model_${modelKey}.class_${classKey}`).style("fill", this.colorScale(parseInt(modelKey.substring(5, 7))-1));
					this.group
						.selectAll(`rect.square.model_${modelKey}.class_${classKey}`)
						.style("fill", this.colorScale(mk));

					this.group
						.selectAll(`rect.square.model_${modelKey}.class_${classKey}`)
						.style("stroke", "lightgray");

					this.group.selectAll("rect.square").style("z-index", "100");

					this.group.selectAll("path.path").style("z-index", "0");

					modelIterator++;
				}
			});
		});
	}

	unhighlightAll() {
		// // console.log(this.group.selectAll('path.path'))
		this.group.selectAll("path.path").style("stroke", "rgba(2, 11, 20, .15)");
		this.group.selectAll("rect.square").style("fill", "rgba(2, 11, 20, 0)");
		this.group.selectAll("rect.square").style("stroke", "rgba(2, 11, 20, 0)");
		// this.group.selectAll('path.plane').style('fill', 'rgba(0, 150, 150, .15)');
	}
}

export default ClassConfusions;
