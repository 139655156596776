import React from 'react';
import './Information.css';

class Information extends React.Component {

    render() {
        return (
            <div id="Information" className="component">

                <div id="header-level" className="component flex-grow-1 d-flex flex-row">
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <h4> ConfusionVis </h4>
                        </div>

                        <div className="cv-card-body">

                            ConfusionVis is a model-agnostic approach which allows to evaluate and compare multi-class classifiers based on their confusion matrices.
                            <br/><br/>
                            The UI is subdivided into three horizontal subpanes:
                            <br/><br/>
<ul className="b">
                                <li>top subpane: accuracies and similarities of models</li>
                                <li> middle subpane: per-class errors</li>
                                <li> bottom subpane: class confusions showing the errors</li>
                            </ul>

                            <br/><br/>
                        The visualisations are described below.

                        </div>

                    </div>


                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <h4>  Selecting models and classes</h4>
                        </div>

                        <div className="cv-card-body">

                            <b>1. Selecting items: by mouse click or in parallel coordinates by holding mouse button and selecting a range</b>
<br />
<br />
                            <b>2 a. Enhancing selection: hold “e” (=enhance) + next selection</b>
                            <br />
                            <br />
Example:
<ul className="b">

                                <li>step 1: select model 1 </li>
                                <li>step 2: hold “e” + select model 8</li>
                                <li>result: model 1 and model 8 selected (logical OR of previous and current selection)</li>
                            </ul>

                            <br />
                            <br />

                            <b>2 b. Refining selection: hold “r” (=refine) + next selection</b>
                            <br />
                            <br />
Example:
<ul className="b">
                                <li>step 1: select model 1-8 </li>
                                <li>step 2: hold “r” + select model 2-4</li>
                                <li>result: model 2-4 selected (logical AND of previous and current selection)</li>
                            </ul>

                            <br />
                            <br />

                            <b>3. Reset selection: refresh your browser (e.g. F5)</b>

                        </div>
                    </div>
                </div>

                <div id="first-level" className="component flex-grow-1 d-flex flex-row">
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Averaged accuracy per model</span>
                        </div>
                        <div className="cv-card-body">
                            The models' averaged accuracies are shown in an enhanced scatter plot. Horizontal lines allow for easy comparison between models.
                        <br />
                            <br />
The visualisation can be used to, e.g.:
<br />
                            <ul className="b">
                                <li>identify generally weak and strong models</li>
                            </ul>

                        </div>
                    </div>
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Confusion matrix similarity</span>
                        </div>
                        <div className="cv-card-body">

                            The distances between the model’s confusion matrices are shown in a scatter plot.
                            Models with similar class confusions are positioned closer to each other.
<br />
                            <br />
The visualisation can be used to, e.g.:
<ul className="b">

                                <li>identify similar models that could be discarded from further refinement</li>
                                <li>identify dissimilar models that could be candidates for an ensemble</li>
                                <li>if models were trained on different feature subsets, the effects of the selected features can be investigated</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="second-level" className="component flex-grow-1 d-flex flex-row">
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Error-by-class</span>
                        </div>
                        <div className="cv-card-body">

                            The per-class errors of all models are shown in an interactive parallel coordinates plot.
                            The first axis holds the index of the models, the remaining axes show the per-class errors on one axis per class.
<br />
                            <br />
The visualisation can be used to, e.g.:
<ul className="b">
                                <li>get an overview over all per-class errors</li>
                                <li>query for all errors of one model</li>
                                <li>identify the model with the lowest or highest error on a specific class</li>
                                <li>compare several models w.r.t. their errors on specific classes</li>
                            </ul>
                        </div>
                    </div>
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Error-by-model</span>
                        </div>
                        <div className="cv-card-body">

                            The per-class errors are shown for each model in a parallel coordinates plot
                            The first axis holds the class, the remaining axes show the errors on the corresponding class, one axis per model.

<br />
                            <br />
The visualisation can be used to, e.g.:

<ul className="b">

                                <li>see the distribution of the class errors for each model </li>
                                <li>query for errors on one class over all models </li>
                                <li>compare several models w.r.t. their errors on specific classes </li>
                                <li>identify models with high/low class errors on individual classes </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div id="third-level" className="component flex-grow-1 d-flex flex-row">
                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Confusion Matrix per Model</span>
                        </div>
                        <div className="cv-card-body">

                            The class confusions of all models are shown as confusion matrices (columns = class labels, rows = predictions).
                            Cell colours: white for error=0, darker colours for higher errors. 
                            <br/>
                            Columns can be selected, where one column corresponds to the class confusions of the selected class for the selected model.
<br />
                            <br />
The visualisation can be used to, e.g.:
<ul className="b">

                                <li>	spot models with high or low class confusions</li>
                                <li>	identify similarities and dissimilarities in the models' class confusions</li>
                                <li>	identify problem classes and classification imbalances</li>
                            </ul>
                        </div>
                    </div>

                    <div className="cv-card flex-grow-1 d-flex flex-column">
                        <div className="cv-card-header">
                            <span>Visualisation: Class confusions</span>
                        </div>
                        <div className="cv-card-body">

                            The class confusions of all models are shown in a parallel coordinates plot.
                            The first axis holds the models, the second axis the true class labels (target class). The remaining axes show the predictions.
                            A line path in this plot corresponds to a column in one confusion matrix.
<br />
                            <br />
The visualisation can be used to, e.g.:
<ul className="b">
                                <li>query for specific class confusions, e.g. class 1 => class 2 </li>
                                <li>identify pairs of classes that are frequently confused by all models which can be an indication for
                             fuzzy class separability or potential mislabelling of these classes' data points</li>
                                <li>identify contradicting models which are candidates for an ensemble</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default Information;