class AppService {

    static initModelNames(data) {
        return Object.keys(data[0]).map((d, i) => 'model' + (i + 1));
    }

    static initSelection(data, modelNames) {
        let result = {};

        // console.log('+++ init model selection +++');
        // console.log(data, modelNames);
        let modelNamesKeys = Object.keys(modelNames);

        modelNamesKeys.forEach(key1 => {
            let temp = {}
            data.columns.forEach(key2 => {
                // console.log(key2)
                temp[key2] = false;
            });

            // temp.all = false;
            result[key1] = temp;
        });
        // console.log(modelNames)

        // console.log(result);
        // console.log('--- init model selection ---');

        return result;
    }

    static invertSelection(selection) {
        let newSelection = {};

        Object.keys(selection).forEach(key1 => {
            let temp = {};
            Object.keys(selection[key1]).forEach(key2 => {
                temp[key2] = !selection[key1][key2];
            })
            newSelection[key1] = temp;
        })
        return newSelection;
    }

    static resetSelection(selection) {
        let result = {};

        Object.entries(selection).forEach(([key1, val1]) => {
            let temp = {}
            Object.entries(val1).forEach(([key2, val2]) => {
                temp[key2] = false;
            })
            result[key1] = temp;
        })
        return result;
    }

    static emptySelection(selection) {
        let result = {};

        Object.entries(selection).forEach(([key1, val1]) => {
            let temp = {}
            Object.entries(val1).forEach(([key2, val2]) => {
                temp[key2] = false;
            })
            result[key1] = temp;
        })
        return result;
    }


    /**
     *  Selection operations
     * 
     *  A: present selection
     *  B: new selection (/changes)
     *  C: resulting selection
     * 
     *  Default selection: 
     *  OR selection: 
     *  AND selection: 
     * 
     *  Double selection always results in a deselect
     */

    // `class_${}` `model_{}`

    /**
     * 
     */
    static processSelection(selection, changes) {
        // console.log('+++ processSelection +++');
        // console.log(selection);
        // console.log(changes);

        let result = {};
        switch (changes['mode']) {
            case 'DEFAULT':
                console.log('DEFAULT');
                result = this.processSelectionDEFAULT(selection, changes);
                break;
            case 'OR':
                console.log('OR');
                result = this.processSelectionOR(selection, changes);
                break;
            case 'AND':
                console.log('AND');
                result = this.processSelectionAND(selection, changes);
                break;
            default:
                console.error('processSelection switch: default-case, should not get called.');
                result = selection;
                break;
        }

        // console.log('--- processSelection ---');
        return result;
    }

    /**
     * 
     */
    static processSelectionDEFAULT(selection, changes) {
        // console.log('+++ processSelectionDEFAULT +++');
        // console.log(selection);
        // console.log(changes);

        let result = {};
        switch (changes['indicator']) {
            case 'MODEL':
                console.log('MODEL');
                result = this.modelSelectionDEFAULT(this.emptySelection(selection), changes['models']);
                break;
            case 'CLASS':
                console.log('CLASS');
                result = this.classSelectionDEFAULT(this.emptySelection(selection), changes['classes']);
                break;
            case 'COMPLEX':
                console.log('COMPLEX');
                result = this.complexSelectionDEFAULT(this.emptySelection(selection), changes['modelsWithClasses']);
                break;
            default:
                console.error('Ups. Something went wrong.')
                result = selection;
                break;
        }

        // console.log(result)
        // console.log('--- processSelectionDEFAULT ---');
        return result;
    }

    /**
     * 
     */
    static processSelectionOR(selection, changes) {

        // console.log('+++ processSelectionOR +++');
        // console.log(selection);
        // console.log(changes);

        let result = {};
        switch (changes['indicator']) {
            case 'MODEL':
                // console.log('MODEL');
                result = this.modelSelectionOR(selection, changes['models']);
                break;
            case 'CLASS':
                // console.log('CLASS');
                result = this.classSelectionOR(selection, changes['classes']);
                break;
            case 'COMPLEX':
                // console.log('COMPLEX');
                result = this.complexSelectionOR(selection, changes['modelsWithClasses']);
                break;
            default:
                console.error('Ups. Something went wrong.')
                result = selection;
                break;
        }

        // console.log(result);
        // console.log('--- processSelectionOR ---');
        return result;
    }

    /**
     * 
     */
    static processSelectionAND(selection, changes) {

        // console.log('+++ processSelectionAND +++');
        // console.log(selection);
        // console.log(changes);

        let result = {};
        switch (changes['indicator']) {
            case 'MODEL':
                console.log('MODEL');
                result = this.modelSelectionAND(selection, this.modelSelectionDEFAULT(this.emptySelection(selection), changes['models']));
                break;
            case 'CLASS':
                console.log('CLASS');
                result = this.classSelectionAND(selection, this.classSelectionDEFAULT(this.emptySelection(selection), changes['classes']));
                break;
            case 'COMPLEX':
                console.log('COMPLEX');
                result = this.complexSelectionAND(selection, this.complexSelectionDEFAULT(this.emptySelection(selection), changes['modelsWithClasses']));
                break;
            default:
                console.error('Ups. Something went wrong.')
                result = selection;
                break;
        }

        // console.log(result);
        // console.log('--- processSelectionAND ---');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static modelSelectionDEFAULT(selection, changes) {

        // console.log('+++ modelSelectionDEFAULT +++');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        // console.log(changes);
        // console.log(result);
        changes.forEach(element => {
            // console.log(element)
            let temp = result[element];

            Object.keys(temp).forEach(key => {
                temp[key] = true;
            });
        });

        console.log(result);
        // console.log('--- modelSelectionDEFAULT ---');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static modelSelectionOR(selection, changes) {
        // console.log('%c+++ modelSelectionOR +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        // console.log(changes);
        // console.log(result);
        changes.forEach(element => {
            // console.log(element)
            let temp = result[element];

            // console.log(temp)
            Object.keys(temp).forEach(key => {
                // console.log(key)
                temp[key] = true;
            })
        })

        // console.log(result);
        // console.log('%c--- modelSelectionOR ---', 'color: #FFFF00');
        return result;
    }

    /**
     * 
     * @state open
     */
    static modelSelectionAND(selection, changes) {
        // console.log('%c+++ modelSelectionAND +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;

        let isInitial = true;

        // iterate over models
        Object.keys(result).forEach(key1 => {
            if (!isInitial) return;

            // iterate over classes
            Object.keys(result[key1]).forEach(key2 => {
                if (!isInitial) return;
                if (result[key1][key2] === true) isInitial = false;
            })
        })

        if (isInitial) {
            result = changes;
        }
        else {
            // iterate over models
            Object.keys(selection).forEach(modelKey => {

                // iterate over classes
                Object.keys(selection[modelKey]).forEach(classKey => {

                    // AND operation (compares selction and changes)
                    if (selection[modelKey][classKey] && changes[modelKey][classKey]) result[modelKey][classKey] = true;
                    else result[modelKey][classKey] = false;
                })
            })
        }


        // console.log(selection);
        // console.log('%c--- modelSelectionAND ---', 'color: #FFFF00');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static classSelectionDEFAULT(selection, changes) {
        // console.log('%c+++ classSelectionDEFAULT +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        Object.keys(result).forEach(key => {
            changes.forEach(changesClass => {
                result[key][changesClass] = true;
            });
        });

        // console.log(selection);
        // console.log('%c--- classSelectionDEFAULT ---', 'color: #FFFF00');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static classSelectionOR(selection, changes) {
        //selection: list of key value pairs models =>[ classes => selected ]
        //changes: Array of class names
        //selection[element]: key-value pair of all classes of current model
        // console.log('%c+++ classSelectionOR +++', 'color: #80C904');
        // console.log(selection);
        // console.log(changes);

        let result = selection;
        Object.keys(result).forEach(key => {
            changes.forEach(changesClass => {
                result[key][changesClass] = true;
            });
        });

        // console.log(result);
        // console.log('%c--- classSelectionOR ---', 'color: #FFFF00');
        return selection;
    }

    /**
     * 
     * @state DONE
     */
    static classSelectionAND(selection, changes) {
        //selection: list of key value pairs models =>[ classes => selected ]
        //changes: Array of class names
        //selection[element]: key-value pair of all classes of current model
        // console.log('+++ classSelectionAND +++');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;

        let isInitial = true;

        // iterate over models
        Object.keys(result).forEach(key1 => {
            if (!isInitial) return;

            // iterate over classes
            Object.keys(result[key1]).forEach(key2 => {
                if (!isInitial) return;
                if (result[key1][key2] === true) isInitial = false;
            })
        })

        if (isInitial) {
            result = changes;
        }
        else {
            // iterate over models
            Object.keys(selection).forEach(modelKey => {

                // iterate over classes
                Object.keys(selection[modelKey]).forEach(classKey => {

                    // AND operation (compares selction and changes)
                    if (selection[modelKey][classKey] && changes[modelKey][classKey]) result[modelKey][classKey] = true;
                    else result[modelKey][classKey] = false;
                })
            })
        }

        // console.log('--- classSelectionAND ---');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static complexSelectionDEFAULT(selection, changes) {
        // console.log('%c+++ complexSelectionDEFAULT +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        Object.keys(changes).forEach(key => {
            changes[key].forEach(changesClass => {
                result[key][changesClass] = true;
            })
        });

        // console.log(result);
        // console.log('%c--- complexSelectionDEFAULT ---', 'color: #FFFF00');
        return result;
    }

    /**
     * 
     * @state DONE
     */
    static complexSelectionOR(selection, changes) {
        //changes: Array of arrays: one item per model
        // array[0][0] model name
        // array [0][1] array of class names 

        // console.log('%c+++ complexSelectionOR +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        Object.keys(changes).forEach(key => {
            changes[key].forEach(changesClass => {
                result[key][changesClass] = true;
            })
        });

        // console.log(result);
        // console.log('%c--- complexSelectionOR ---', 'color: #FFFF00');
        return result;
    }


    /**
     * 
     * @state DONE
     */
    static complexSelectionAND(selection, changes) {
        //changes: Array of arrays: one item per model
        // array[0][0] model name
        // array [0][1] array of class names 

        // console.log('%c+++ complexSelectionAND +++', 'color: #80C904');
        // console.log('selection: ', selection);
        // console.log('changes: ', changes);

        let result = selection;
        // (1) //models: if model is not contained in new selection => deselect all classes of that model
        // (2) //model not contained in new selection? 
        // (2.1) deselect all classes of this model
        // (3) //classes: if class is not contained in new selection => deselect this class in all models
        // (3.1) Array of class names is in [1] 
        // (3.2) refine selection, remove previously selected items that are not selected in current selection...

        let isInitial = true;


        Object.keys(result).forEach(key1 => {
            if (!isInitial) return;

            // iterate over classes
            Object.keys(result[key1]).forEach(key2 => {
                if (!isInitial) return;
                if (result[key1][key2] === true) isInitial = false;
            })
        })

        if (isInitial) {
            result = changes;
        }
        else {
            // iterate over models
            Object.keys(selection).forEach(modelKey => {

                // iterate over classes
                Object.keys(selection[modelKey]).forEach(classKey => {
                    // console.log(modelKey);
                    // console.log(classKey);
                    // console.log(selection[modelKey][classKey]);
                    // console.log(changes[modelKey][classKey]);

                    // AND operation
                    if (selection[modelKey][classKey] && changes[modelKey][classKey]) result[modelKey][classKey] = true;
                    else result[modelKey][classKey] = false;
                })
            })

        }

        // console.log(result);
        // console.log('%c--- complexSelectionAND ---', 'color: #FFFF00');
        return result;
    }

}

export default AppService;