import React from "react";
import * as d3 from "d3";

import "./Data.css";
import CVForm from "../../components/CVForm";
import {
	checkAndReturnData,
	initClassNames,
	initModelNames,
} from "../../utils/helpers";

class Data extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formRenderIndicator: 0,
		};

		// refs
		this.structureSelect = React.createRef();
		this.dropZone = React.createRef();
		this.fileInput = React.createRef();

		// bind class functions
		this.onClickFileUpload = this.onClickFileUpload.bind(this);
		this.onChangeFileUpload = this.onChangeFileUpload.bind(this);
		this.onDropFileUpload = this.onDropFileUpload.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);

		this.changeStructure = this.changeStructure.bind(this);
		this.updateModelNames = this.updateModelNames.bind(this);
	}

	componentDidMount() {
		// // console.log(this);
	}

	readFile(file, provider) {
		// TODO: refactor
		if (file === undefined) {
			// // // console.log(file);
			return;
		} //TODO:
		let reader = new FileReader();
		reader.readAsText(file);
		reader.onloadend = (event) => {
			let data = d3.csvParse(
				event.target.result.replace(/ |\t/g, ""),
				(data, i, cols) => {
					return data;
				}
			);

			// // console.log(data);

			let checkedData;
			try {
				checkedData = checkAndReturnData(data);
			} catch (error) {
				this.props.addToast("error", error);
				this.props.addToast("error", "Your data upload failed!");
				return;
			}

			let modelNames = initModelNames(checkedData);
			let classNames = initClassNames(checkedData);

			// // // console.log(modelNames);

			if (checkedData === null) {
				this.props.addToast("error", "Your data upload failed!");
			} else {
				this.props.updateData(checkedData);
				this.props.updateModelNames(modelNames);

				if (provider !== undefined) provider.classList.remove("active");
				this.props.addToast("success", "Your data got succesfully uploaded!");

				this.data = checkedData;
				// // // console.log(this.data)
			}
		};
		reader.onerror = (error) => {
			this.props.addToast("error", error);
			this.props.addToast("error", "Your data upload failed!");
		};
	}

	onClickFileUpload(event) {
		this.fileInput.current.click();
	}

	onChangeFileUpload(event) {
		this.readFile(event.target.files[0]);
	}

	onDropFileUpload(event) {
		event.preventDefault();
		this.readFile(event.dataTransfer.files[0], this.dropZone.current);
	}

	onDragOver(event) {
		event.preventDefault();
		this.dropZone.current.classList.add("active");
	}

	onDragLeave(event) {
		event.preventDefault();
		this.dropZone.current.classList.remove("active");
	}

	changeStructure(event) {
		let value = this.structureSelect.current.value;
		if (value === "0") alert("select input structure");
		else this.props.updateStructure(value);

		this.props.addToast("success", "Structure changed successfully");
	}

	updateModelNames(modelNames) {
		if (modelNames === 0) {
			this.props.addToast("error", "Something went wrong!");
		} else {
			this.props.updateModelNames(modelNames);
			this.props.addToast("success", "Model names changed successfully!");
		}
		this.setState({ formRenderIndicator: Math.random() });
	}

	render() {
		return (
			<div id="Data" className="container-fluid flex-grow-1 d-flex flex-column">
				<div className="component d-flex flex-row">
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Choose your input structure</span>
						</div>
						<div className="cv-card-body d-flex flex-column">
							<div className="cv-card-body-component d-flex">
								<div className="col col-md-4 mr-2">
									<select
										ref={this.structureSelect}
										onChange={this.changeStructure}
										defaultValue={this.props.getStructure}
									>
										<option value={"none"} disabled>
											no structure selected
										</option>
										<option value={"labelsInCols"}>labels in columns</option>
										<option value={"labelsInRows"}>labels in rows</option>
									</select>
								</div>
								{/* <div>
                                    <button onClick={this.changeStructure}>
                                        <span>Change structure</span>
                                    </button>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="component d-flex flex-row">
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Upload your file</span>
						</div>
						<div className="cv-card-body flex-grow-1 d-flex flex-column">
							<div
								className="cv-card-body-component drop-zone d-flex flex-column align-items-center justify-content-center"
								onDrop={this.onDropFileUpload}
								onDragOver={this.onDragOver}
								onDragLeave={this.onDragLeave}
								ref={this.dropZone}
							>
								<span>Drag and drop your file here</span>
								<span>or</span>
								<button onClick={this.onClickFileUpload}>Choose a file</button>
								<input
									type="file"
									ref={this.fileInput}
									onChange={this.onChangeFileUpload}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="component d-flex flex-row">
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Name your models</span>
						</div>
						<div className="cv-card-body flex-grow-1 d-flex flex-column">
							Currently not available.
							{/* <div className="cv-card-body-component d-flex flex-wrap">
                                <CVForm
                                    key={this.state.formRenderIndicator}
                                    data={this.props.modelNames}
                                    updateModelNames={this.updateModelNames}
                                    addToast={this.props.addToast}
                                />
                            </div> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Data;
