// TODO: only import what u use
import * as d3 from 'd3';
import $ from 'jquery';
import { extractModelNumber } from '../utils/helpers';

const MARGIN = { TOP: 25, BOTTOM: 10, LEFT: 25, RIGHT: 100 };

//used for confusion matrix similarity view
class ScatterPlot {
    // eslint-disable-next-line
    constructor(element, data, updateSelection, shiftKeyPressed, altKeyPressed) {
        this.element = element;
        this.data = data;
        this.selection = {};
        this.selectionStateInitial = true;

        this.updateSelection = updateSelection;
        this.shiftKeyPressed = shiftKeyPressed;
        this.altKeyPressed = altKeyPressed;

        this.createPlot();

        // console.log("Test 4")
    }

    createPlot() {

        if (this.data == null) return;

        // console.log("Test 1")
        

        console.warn("Test to find out what does not work ")

        this.container = d3.select(this.element);

        this.width = $(this.element).width();
        this.height = $(this.element).height();

        this.svg = this.container.append('svg')
            .attr('viewBox', [0, 0, this.width, this.height]);

        this.width = this.width - MARGIN.LEFT - MARGIN.RIGHT;
        this.height = this.height - MARGIN.TOP - MARGIN.BOTTOM;

        // determines x and y scaling factor based on the limiting dimension (height or width)
        if (this.width > this.height) {
            this.xScalingFactor = this.width / this.height;
            this.yScalingFactor = 1;
        }
        else if (this.width < this.height) {
            this.xScalingFactor = 1;
            this.yScalingFactor = this.height / this.width;
        }

        this.xScale = d3.scaleLinear()
            .range([0, this.width]);

        this.yScale = d3.scaleLinear()
            .range([this.height, 0])

        this.tooltip = this.container.append('div')
            .attr('id', 'sp-tooltip')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('display', 'none');

        this.group = this.svg.append('g')
            .attr('transform', `translate(${MARGIN.LEFT * 2}, ${MARGIN.BOTTOM + MARGIN.TOP * 1.25})`);

        this.xAxis = this.group.append('g')
            .attr('transform', `translate(0, ${this.height - MARGIN.BOTTOM - MARGIN.TOP})`)
            .attr('class', 'axis xaxis')
            .style('z-index', '200');

        this.yAxis = this.group.append('g')
            .attr('transform', `translate(0, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', 'axis yaxis')
            .style('z-index', '200');

        // console.log("Test 2")
        
        this.updatePlot();
        // console.log("Test 3")
    }

    updatePlot() {

        let dataMinX = d3.min(this.data.map(d => d.x)), dataMaxX = d3.max(this.data.map(d => d.x));
        let dataMinY = d3.min(this.data.map(d => d.y)), dataMaxY = d3.max(this.data.map(d => d.y));


        let scaledDomain = [];

        // equalize scaleDomain of x and y
        if (dataMinX < dataMinY) scaledDomain[0] = dataMinX;
        else scaledDomain[0] = dataMinY;
        if (dataMaxX > dataMaxY) scaledDomain[1] = dataMaxX;
        else scaledDomain[1] = dataMaxY;

        this.xScale.domain([scaledDomain[0], scaledDomain[1] * this.xScalingFactor]);
        this.yScale.domain([scaledDomain[0], scaledDomain[1] * this.yScalingFactor]);


        //scaling for accuracies
        var minAcc = d3.min( this.data.map(d => d.accuracy));
        var maxAcc = d3.max( this.data.map(d => d.accuracy));

        //radius of the circles
        var accScale = d3.scaleLinear().            
                            range([7, 12]).
                            domain([minAcc, maxAcc]);

        // TODO: split -> transition
        this.xAxis.call(d3.axisBottom(this.xScale));
        this.yAxis.call(d3.axisLeft(this.yScale));

        // JOIN
        this.labels = this.group
            .selectAll('text.sp-label')
            .data(this.data);

        this.dots = this.group
            .selectAll('circle')
            .data(this.data);

        // ENTER


        this.dots.enter().append('circle')
            .attr("transform", `translate(0, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', d => `dot model_${d.model}`)
            .attr('r', d => accScale(d.accuracy))
            .attr('cx', d => this.xScale(d.x))  
            .attr('cy', d => this.yScale(d.y))  
            .style('opacity', 0.7)
            .attr('stroke', 'black')
            .style('fill', '#1976D2')
            .on('click', (event, d) => this.select(d.model))
            .on('mouseover', (event, d) => {
                this.tooltip.transition()
                    .duration(275)
                    .style('opacity', .8)
                    .style('display', 'inline-block')

                this.tooltip.html(`model: ${d.model}`)
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY}px`)
                    .style('z-index', 400);
            })
            .on('mouseout', d => {
                this.tooltip.transition()
                    .duration(0)
                    .style('opacity', 0)
                    .style('display', 'none');
            })
            ;



        this.labels.enter().append('text')
            .attr("transform", `translate(0, -${MARGIN.BOTTOM + MARGIN.TOP})`)
            .attr('class', 'sp-label')
            .attr('x', d => this.xScale(d.x) - ( (extractModelNumber(d.model)<10) ? 3 : 6) ) //+6 if domain min/max 
            .attr('y', d => this.yScale(d.y) + 3.5 )  //+3
            //.style('opacity', 0.8)
            .on('click', (event, d) => this.select(d.model))
            .text(d => extractModelNumber(d.model) )  //just show the number to save space
            .style('font-size', '.6rem')
            .attr('font-weight', 700)
            // .style('pointer-events', 'none')
            .style('user-select', 'none')
            .on('mouseover', (event, d) => {
                this.tooltip.transition()
                    .duration(275)
                    .style('opacity', .9)
                    .style('display', 'inline-block')

                this.tooltip.html(`model: ${d.model}`)
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY}px`)
                    .style('z-index', 400);
            })
            .on('mouseout', d => {
                this.tooltip.transition()
                    .duration(0)
                    .style('opacity', 0)
                    .style('display', 'none');
            })
            ;

        this.group.selectAll('g.axis text')
            .style('pointer-events', 'none')
            .style('user-select', 'none');
    }

    highlightSelection(selection) {
        this.unhighlightAll();
        Object.entries(selection).forEach(([key, val]) => {
            let keys = Object.keys(val);
            let thruthyIterator = 0;
            let threshold = keys.length;
            keys.forEach(key => {
                if (val[key] === true) thruthyIterator++;
            })
            if (thruthyIterator === threshold) this.group.select(`.dot.model_${key}`).style('fill', '#1976D2');
        });
    }

    select(selected) {
        let selection = {};

        // console.log('TEST')

        selection['indicator'] = 'MODEL';
        if (this.shiftKeyPressed()) {
            selection['mode'] = 'OR';
            selection['models'] = [selected];
            this.updateSelection(selection);
        }
        else if (this.altKeyPressed()) {
            selection['mode'] = 'AND';
            selection['models'] = [selected];
            this.updateSelection(selection);
        }
        else {
            selection['mode'] = 'DEFAULT';
            selection['models'] = [selected];
            this.updateSelection(selection);
        }
    }

    unhighlightAll() {
        this.group.selectAll('.dot').style('fill', 'rgba(2, 11, 20, .15)');
    }

    resetHighlight() {
        this.group.selectAll('.dot').style('fill', '#1976D2');
    }

}

export default ScatterPlot;