import React from "react";
import DataProcessor from "../../utils/DataProcessor";
import {
	calculateAverageAccuracy,
	calculateAveragePrevalence,
	calculateMacroAveragePrecision,
	calculateMacroAverageRecall,
	calculateMacroAverageF1Score,
} from "../../utils/metrics";

import "./Metrics.css";

class Metrics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			structure: null,
			data: null,
		};

		this.metrics = {
			accuracy: null,
			prevalence: null,
			precision: null,
			recall: null,
			f1score: null,
		};
	}

	componentDidMount() {
		// // console.log(this.props);
		this.setState({
			structure: this.props.structure,
			data: this.props.data,
			modelNames: this.props.modelNames,
		});
	}

	componentDidUpdate() {
		if (this.state.data !== null && this.metrics.accuracy == null) {
			this.calculateAndSetMetrics();
			this.forceUpdate();
		}
	}

	calculateAndSetMetrics() {
		let processor;

		let modelNamesOnlyNames = Object.keys(this.state.modelNames).map(
			(key) => this.state.modelNames[key]
		);

		if (this.state.structure === "labelsInRows")
			processor = new DataProcessor(
				DataProcessor.processAsLabelsInRows(
					this.state.data,
					modelNamesOnlyNames
				)
			);
		else
			processor = new DataProcessor(
				DataProcessor.processAsLabelsInCols(
					this.state.data,
					modelNamesOnlyNames
				)
			);

		let confMatrices = processor.getMatrices();

		this.metrics = {
			accuracy: calculateAverageAccuracy(processor.getBarChartData()).toFixed(
				4
			),
			prevalence: calculateAveragePrevalence(confMatrices).toFixed(4),
			precision: calculateMacroAveragePrecision(confMatrices).toFixed(4),
			recall: calculateMacroAverageRecall(confMatrices).toFixed(4),
			f1score: calculateMacroAverageF1Score(confMatrices).toFixed(4),
		};

		// // console.log(this.metrics);
	}

	render() {
		return (
			<div id="Metrics" className="container-fluid">
				<div className="component d-flex flex-row">
					<div className="col cv-metric-card">
						<div className="cv-metric-card-header">Avg. Accuracy</div>
						<div className="cv-metric-card-body">
							<span>{this.metrics.accuracy}</span>
						</div>
						<div className="cv-metric-card-footer">Over all models</div>
					</div>
					<div className="col cv-metric-card">
						<div className="cv-metric-card-header">Prevalence</div>
						<div className="cv-metric-card-body">
							<span>{this.metrics.prevalence}</span>
						</div>
						<div className="cv-metric-card-footer">Over all models</div>
					</div>
					<div className="col cv-metric-card">
						<div className="cv-metric-card-header">Macro-Avg. Precision</div>
						<div className="cv-metric-card-body">
							<span>{this.metrics.precision}</span>
						</div>
						<div className="cv-metric-card-footer">Over all models</div>
					</div>
					<div className="col cv-metric-card">
						<div className="cv-metric-card-header">Macro-Avg. Recall</div>
						<div className="cv-metric-card-body">
							<span>{this.metrics.recall}</span>
						</div>
						<div className="cv-metric-card-footer">Over all models</div>
					</div>
					<div className="col cv-metric-card">
						<div className="cv-metric-card-header">Macro-Avg. F1-Score</div>
						<div className="cv-metric-card-body">
							<span>{this.metrics.f1score}</span>
						</div>
						<div className="cv-metric-card-footer">Over all models</div>
					</div>
					{/* <div className="col cv-metric-card">Micro ?</div> */}
				</div>
			</div>
		);
	}
}
export default Metrics;
