import React from "react";
import BarChart from "../../plots/BarChart";
import ScatterPlot from "../../plots/ScatterPlot";
import ParallelCoordinate from "../../plots/ParallelCoordinate";
import DataProcessor from "../../utils/DataProcessor";
import ConfusionMatrixContainer from "../../plots/ConfusionMatrixContainer";

import "./Dashboard.css";
import AppService from "../../utils/AppService";
import ClassConfusions from "../../plots/ClassConfusions";

class Dashboard extends React.Component {
	confRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			data: null,	
			structure: null,
			modelNames: null,
			selection: null,
		};

		this.keysPressed = {
			shiftKey: false,
			altKey: false,
			f5Key: false,
		};

		this.plots = {};
		this.processor = null;
		this.initial = true;
		this.resizeEnd = null;

		this.renderSidebar = this.renderSidebar.bind(this);
		this.updateSelection = this.updateSelection.bind(this);
		this.highlightPlots = this.highlightPlots.bind(this);
		this.checkIfShiftKeyPressed = this.checkIfShiftKeyPressed.bind(this);
		this.checkIfAltKeyPressed = this.checkIfAltKeyPressed.bind(this);
	}

	componentDidMount() {
		this.renderSidebar();
		this.testplotRef = React.createRef();

		// console.log(this.props.data);

		this.refs = {
			avAcc: React.createRef(),
			cmSim: React.createRef(),
			erCls: React.createRef(),
			erMdl: React.createRef(),
			cmCon: React.createRef(),
			clCon: React.createRef()
		}

		// console.log(this.props);

		this.setState({
			data: this.props.data,
			structure: this.props.structure,
			modelNames: this.props.modelNames,
		});

		document.addEventListener("keydown", (event) => {
			switch (event.key) {
				case "Shift": //enhance selection, logical OR
				case "e":
				case "E":
					this.keysPressed.shiftKey = true;
					event.stopPropagation();
					event.preventDefault();
					break;
				case "Alt":
				case "r":
				case "R":
					this.keysPressed.altKey = true;
					event.stopPropagation();
					event.preventDefault();
					break;
				case "F5":
					this.keysPressed.f5Key = true;
					event.stopPropagation();
					event.preventDefault();
					this.renderDashboard(
						this.state.data,
						this.state.structure,
						this.state.modelNames
					);
					break;
				default:
					break;
			}
		});

		document.addEventListener("keyup", (event) => {
			switch (event.key) {
				case "Shift": //enhance selection, logical OR
				case "e":
				case "E":
					this.keysPressed.shiftKey = false;
					event.stopPropagation();
					event.preventDefault();
					break;
				case "Alt": //refine selection, logical AND
				case "r":
				case "R":
					this.keysPressed.altKey = false;
					event.stopPropagation();
					event.preventDefault();
					break;
				case "F5":
					this.keysPressed.f5Key = false;
					event.stopPropagation();
					event.preventDefault();
					break;
				default:
					break;
			}
		});

		window.addEventListener("resize", (event) => {
			clearTimeout(this.resizeEnd);
			this.resizeEnd = setTimeout(this.resizeDashboard(), 1000);
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			data: nextProps.data,
			structure: nextProps.structure,
			modelNames: nextProps.modelNames,
		});

		if (this.state.data !== null && this.state.modelNames !== null) {
			this.renderDashboard(
				this.state.data,
				this.state.structure,
				this.state.modelNames
			);
		}
	}

	componentDidUpdate() {
		// console.log(this);

		if (this.state.data !== null && this.state.modelNames !== null) {
			this.renderDashboard(
				this.state.data,
				this.state.structure,
				this.state.modelNames
			);
		}
	}

	componentDidCatch(error, info) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		// console.log(error, info);
	}

	renderDashboard(data, structure, modelNames, selection) {
		this.clearDashboard();

		// selection -> null??
		// // console.log(selection);
		// // console.log(this.state.selection);
		let modelNamesOnlyNames = Object.keys(modelNames).map(
			(key) => modelNames[key]
		);

		// console.log(data);

		if (structure === "labelsInRows")
			this.processor = new DataProcessor(
				DataProcessor.processAsLabelsInRows(data, modelNamesOnlyNames)
			);
		else
			this.processor = new DataProcessor(
				DataProcessor.processAsLabelsInCols(data, modelNamesOnlyNames)
			);

		// console.log("Test After Data processing.")

		// if(this.selection !== null && this.selection !== undefined) this.selection = appService.resetSelection(this.selection);

		// // console.log(selection);
		// // console.log(this.state.selection);

		this.plots = {
			avAcc: new BarChart(
				this.refs.avAcc.current,
				this.processor.getBarChartData(),
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			),
			cmSim: new ScatterPlot(
				this.refs.cmSim.current,
				this.processor.getConfMatrixSimilarityData(),
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			), // faulty w/ the data provided
			erCls: new ParallelCoordinate(
				this.refs.erCls.current,
				this.processor.getErrorByClassData(),
				this.state.classNames,
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			),
			erMdl: new ParallelCoordinate(
				this.refs.erMdl.current,
				this.processor.getErrorByModelData(),
				this.state.classNames,
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			),
			cmCon: new ConfusionMatrixContainer(
				this.refs.cmCon.current,
				this.processor.getConfusionMatrixData(),
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			),
			clCon: new ClassConfusions(
				this.refs.clCon.current,
				this.processor.getClassConfusions(),
				this.updateSelection,
				this.checkIfShiftKeyPressed,
				this.checkIfAltKeyPressed
			)
		};

		// console.log("Test After plots")

		this.initial = false;
	}

	clearDashboard() {
		for (let ref in this.refs) {
			if(this.refs[ref].current) this.refs[ref].current.innerHTML = "";
		}
		// // console.log(this.testplotRef);
		if (this.testplotRef.current !== null)
			this.testplotRef.current.innerHTML = null;

		if (this.selection !== null && this.selection !== undefined)
			this.selection = AppService.resetSelection(this.selection);
	}

	resizeDashboard() {
		this.renderDashboard(
			this.state.data,
			this.state.structure,
			this.state.modelNames
		);

		if (this.selection !== null && this.selection !== undefined)
			this.highlightPlots(this.selection);
	}

	renderSidebar() {}

	updateSelection(changes) {
		// // console.log('***********************************************************');
		// // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
		// // console.log('***********************************************************');
		// // console.log('(1) pre selection: ', this.selection);
		// // console.log('(1) pre changes: ', changes);
		// console.log("test update selection")

		if (this.selection === undefined || this.selection === null)
			this.selection = AppService.initSelection(
				this.state.data,
				this.state.modelNames
			);

		// // console.log('(2) pre selection: ', this.selection);
		// // console.log('(2) pre changes: ', changes);

		this.selection = AppService.processSelection(this.selection, changes);
		this.highlightPlots(this.selection);

		// // console.log('***********************************************************');
		// // console.log('-----------------------------------------------------------');
		// // console.log('***********************************************************');

	}

	highlightPlots(selection) {
		Object.keys(this.plots).forEach((key) => {
			// if(key === 'avAcc' || key === 'cmSim' || key === 'cmCon')
			this.plots[key].highlightSelection(selection);
		});
	}

	checkIfShiftKeyPressed() {
		return this.keysPressed.shiftKey;
	}

	checkIfAltKeyPressed() {
		return this.keysPressed.altKey;
	}

	componentWillUnmount() {
		// TODO: faultless unsubscribe of all subscriptions (if chrome console complains again)
	}

	render() {
		return (
			<div
				id="Dashboard"
				className="container-fluid flex-grow-1 d-flex flex-column"
			>
				<div id="first-level" className="component flex-grow-1 d-flex flex-row">
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Averaged accuracy per model</span>
						</div>
						<div
							ref={this.refs.avAcc}
							className="cv-card-body cv-plot-container"
						></div>
					</div>
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Confusion matrix similarity</span>
						</div>
						<div 
							ref={this.refs.cmSim}
							className="cv-card-body cv-plot-container"
						></div>
					</div>
				</div>
				<div
					id="second-level"
					className="component flex-grow-1 d-flex flex-row"
				>
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Error-by-class </span>
							<br />
							<span>
								(axis 1: model, other axes : per-class errors of class 1...|C|)
							</span>
						</div>
						<div 
							ref={this.refs.erCls}
							className="cv-card-body cv-plot-container"
						></div>
					</div>
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Error-by-model</span>
							<br />
							<span>
								(axis 1: class, other axes: per-class errors model 1...|M|)
							</span>
						</div>
						<div 
							ref={this.refs.erMdl}
							className="cv-card-body cv-plot-container"
						></div>
					</div>
				</div>
				<div id="third-level" className="component flex-grow-1 d-flex flex-row">
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Confusion matrices </span>
							<br />
							<span>(cols = class labels, rows = predictions)</span>
						</div>
						<div
							ref={this.refs.cmCon}
							className="cv-card-body cv-plot-container d-flex flex-wrap"
						></div>
					</div>
					<div className="cv-card flex-grow-1 d-flex flex-column">
						<div className="cv-card-header">
							<span>Class confusions </span>
							<br />
							<span>
								(axis 1: model, axis 2: class label, other axes: predictions)
							</span>
						</div>
						<div
							ref={this.refs.clCon}
							className="cv-card-body cv-plot-container"
						></div>
					</div>
				</div>
			</div>
		);
	}
}
export default Dashboard;
