import React from 'react';

class CVForm extends React.Component {

    constructor(props) {
        super(props);

        this.prevValues = []        
        this.handleSubmit = this.handleSubmit.bind(this);

        console.log(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        let errorenouesValue = false;

        let inputs = event.target.getElementsByTagName('input');
        
        console.log(this.props);

        let submit = this.props.data.map((d, i) => {
            let result = inputs[i].value.trim();
            if(result === '') errorenouesValue = true;
            return result;
        });

        console.log(inputs)
        console.log(errorenouesValue)
        console.log(errorenouesValue)
        if (errorenouesValue === true) this.props.updateModelNames(0);
        else this.props.updateModelNames(submit);
    }

    render() {
        let models = Object.keys(this.props.data);
        
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    models.map((element, i) => {
                        this.prevValues[i] = element;
                        return (
                            <div key={i} className="row mb-1">
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <label>{ element }</label>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <input type="text" maxLength="10" defaultValue={ this.props.data[element] } />
                                </div>
                            </div>
                        )
                    })
                }
                <input type="submit" className="mt-2" value="Change model names" readOnly></input>
            </form>
        );
    }
}
export default CVForm;
